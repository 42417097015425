import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function Editprofilemodal({styles} : any) {

    const [isSubmitting, setisSubmitting] = useState(false);
    const [isDisabled, setisDisabled] = useState(true)

    const validationSchema = Yup.object().shape({
        fullname: Yup.string()
            .required('Full name is required'),
        emailaddress: Yup.string().required('Email is required').email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 40 characters')
    });

    const login = useFormik({
        initialValues: {
            fullname: 'John Markers',
            emailaddress: 'Johnmarkers22@gmail.com',
            password: '4948@Dhruv'
        },
        validationSchema,
        onSubmit: (data: any) => {
            setisSubmitting(true)
            setisDisabled(true)
            return new Promise((resolve) => {
                setTimeout(() => {
                    setisSubmitting(false);
                    setisDisabled(false)
                    resolve(undefined);
                    const element: HTMLButtonElement | any = document.getElementById(`trigger_modal`);
                    element?.click()
                }, 3000)
            })
        },
    });

    return (
        <>
            <div className="modal fade" id="edit_profile_modal">
                <div className={"modal-dialog modal-dialog-centered " + styles.edit_profile_modal_dialog}>
                    <div className={styles.edit_profile_modal_content + " modal-content"}>
                        <div className={styles.edit_profile_modal_header + " modal-header"}>
                            <div className={styles.edit_profile}>
                                <p className={styles.head_edit_profile}>Edit profile</p>
                                <p className={styles.head_edit_para}>Update your profile data</p>
                            </div>
                            <button type="button" className={styles.edit_profile_modal_btn + " btn-close"} data-bs-dismiss="modal">
                                <img src="assets/images/close.png" alt="icon" />
                            </button>
                        </div>

                        <div className={styles.edit_profile_modal_body + " modal-body"}>

                            <form onSubmit={login.handleSubmit}>

                                <div className={styles.input_set_home}>
                                    <label htmlFor="fullname" className={styles.input_label + " form-label"}>Full Name</label>
                                    <input type="text" className={styles.input_field + ' form-control' +
                                        (login.errors.fullname && login.touched.fullname ? ` ${styles.input_field_is_invalid} is-invalid` : '')} id="fullname" name="fullname" defaultValue={login.values.fullname} onChange={login.handleChange} />

                                    <div className={styles.invalid_feedback + " invalid-feedback" }>
                                        {login.errors.fullname && login.touched.fullname
                                            ? login.errors.fullname
                                            : null}
                                    </div>
                                </div>
                                <div className={styles.input_set_home}>
                                    <label htmlFor="emailaddress" className={styles.input_label + " form-label"}>Email</label>
                                    <input type="email" className={styles.input_field + ' form-control' +
                                        (login.errors.emailaddress && login.touched.emailaddress ? ` ${styles.input_field_is_invalid} is-invalid` : '')} id="emailaddress" name="emailaddress" defaultValue={login.values.emailaddress} onChange={login.handleChange} />

                                    <div className={styles.invalid_feedback + " invalid-feedback" } >
                                        {login.errors.emailaddress && login.touched.emailaddress
                                            ? login.errors.emailaddress
                                            : null}
                                    </div>
                                </div>

                                <div className={styles.input_set_home}>
                                    <label htmlFor="password" className={styles.input_label + " form-label"}>Password</label>
                                    <input type="password" className={styles.input_field + ' form-control' +
                                        (login.errors.password && login.touched.password ? ` ${styles.input_field_is_invalid} is-invalid` : '')} id="password" name="password" defaultValue={login.values.password} onChange={login.handleChange} />

                                    <div className={styles.invalid_feedback + " invalid-feedback" } >
                                        {login.errors.password && login.touched.password
                                            ? login.errors.password
                                            : null}
                                    </div>
                                </div>

                                <p className={styles.link_change_password}>
                                    <button type="button" className={styles.change_password_link + " btn"} data-bs-toggle="modal" data-bs-target="#change_password">
                                        Change Password
                                    </button>
                                </p>
                                
                                <button type="submit" className={styles.btn_save + " btn d-flex align-items-center justify-content-center gap-2"} disabled={isSubmitting}>
                                    {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                                    Save</button>

                            </form>

                            <button id="trigger_modal" type="button" className={styles.btn_save + " btn d-none"} data-bs-dismiss="modal">Save</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}