import React, { useEffect, useState } from "react";
import "./reportedItemDetails.css"
import WaveForm from "../waveForm/waveForm"
import SimilarProblemslist from "../similarPronlemslist/similarProblemslist"
import { IssueModel } from "../../../model/issueModel/issueModel";
import VideoThumbnail from 'react-video-thumbnail';

interface reporteditemdetailsprops {
    selecteditem: IssueModel | any;
    handlecloseselecteditem: any;
    styles: any
}

export default function Reporteditemdetails({ selecteditem, handlecloseselecteditem, styles }: reporteditemdetailsprops) {

    const [isTruncate, setIsTruncate] = useState(true)    
    const media = selecteditem.media.filter((media: any) => media.type !== "audio")
    const audio = selecteditem.media.filter((media: any) => media.type === "audio")

    return (
        <>
            {selecteditem.status === "open" && <div className={ styles.open_main_container + " " + styles.open_tab_content}>

                <div className={styles.head_open_close + " d-flex justify-content-between align-items-center"}>
                    <span className={styles.head_open}>{selecteditem.status}</span>
                    <button className={styles.btn_close} onClick={() => handlecloseselecteditem()}>
                        <img src="/assets/images/close.png" alt="icon" />
                    </button>
                </div>

                <div className={styles.set_fault_head_open + " d-flex justify-content-start align-items-center"}>
                    <div className={styles.water_leak_bg}>
                        <img src={selecteditem.created_at} alt="icon" />
                    </div>
                    <div className={styles.fault_data}>
                        <p className={styles.fault_data_head_open}>{selecteditem.remark}</p>
                        <div className={styles.date_para}>
                            <img src="/assets/images/calender-icon.png" alt="icon" className={styles.date_icon_set} />
                            <span className={styles.fault_date}>{selecteditem.created_at}</span>
                            <span className={styles.problem_para}>
                                {selecteditem.reporteditemtype === "Problem" && <img src="/assets/images/caution-icon.png" alt="icon" className={styles.caution_icon_set} />}
                                {selecteditem.reporteditemtype === "Feedback" && <img src="/assets/images/assistant.png" alt="icon" className={styles.caution_icon_set} />}
                                {selecteditem.reporteditemtype === "Answers" && <img src="/assets/images/quickreply.png" alt="icon" className={styles.caution_icon_set} />}
                                {selecteditem.reporteditemtype}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Carousel */}

                <div className={styles.carousel_open}>
                    <p className={styles.head_report_carousel}>Reported photos & video</p>
                    <div id="pipeline_one" className="carousel slide" data-bs-ride="carousel">

                        <div className={styles.image_indicators + " carousel-indicators image_indicators"}>
                            {media.length !== 0 && <>
                                {media.map((media: any, index: number) => {                                    
                                    return (
                                        <button type="button" key={index} data-bs-target="#pipeline_one" data-bs-slide-to={index} aria-current={index === 0 && "true"} className={`${styles.image_indic_set} ${index === 0 ? ` active` : " "}`}>
                                            {media.type === "image" && <img src={`https://kats-media.s3.us-west-2.amazonaws.com${media.path}`} alt="img" className={styles.indic_image} />}
                                            {media.type === "video" && <VideoThumbnail
                                                videoUrl={`https://kats-media.s3.us-west-2.amazonaws.com${media.path}`}
                                            />}
                                            {media.type === "video" && <div className={styles.carousel_indicators_play_button}>
                                                <img src="/assets/images/play_arrow_black.png" alt="play_arrow_black" />
                                            </div>}
                                        </button>
                                    )
                                })}
                            </>}
                            {media.length === 0 && <>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="0"  aria-current={"true"} className={styles.image_indic_set + " active"}>
                                    <img src="/assets/images/pipeline-one.jpeg" alt="img" className={styles.indic_image} />
                                </button>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="1" className={styles.image_indic_set}>
                                    <img src="/assets/images/pipeline-black.jpeg" alt="img" className={styles.indic_image} />
                                </button>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="2" className={styles.image_indic_set}>
                                    <img src="/assets/images/pipeline-black-video.png" alt="img" className={styles.indic_image} />
                                </button>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="3" className={styles.image_indic_set}>
                                    <img src="/assets/images/pipeline-green.png" alt="img" className={styles.indic_image} />
                                </button>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="4" className={styles.image_indic_set}>
                                    <img src="/assets/images/pipeline-plant.png" alt="img" className={styles.indic_image} />
                                </button>
                            </>}
                        </div>

                        <div className={styles.round_indicators_set + " carousel-indicators round_indicators_set"}>
                            {media.length !== 0 && <>
                                {media.map((media: any, index: number) => {
                                    return <button key={index} type="button" data-bs-target="#pipeline_one" data-bs-slide-to={index} aria-current={index === 0 ? "true" : "false"} className={`${styles.round_indicators} ${index === 0 ? "active" : " "}`}></button>
                                })}
                            </>}
                            {media.length === 0 && <>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="0" aria-current="true" className={styles.round_indicators + " active"}></button>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="1" className={styles.round_indicators}></button>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="2" className={styles.round_indicators}></button>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="3" className={styles.round_indicators}></button>
                                <button type="button" data-bs-target="#pipeline_one" data-bs-slide-to="4" className={styles.round_indicators}></button>
                            </>}
                        </div>

                        <div className="carousel-inner">

                            {media.length !== 0 && <>
                                {media.map((media: any, index: number) => {
                                    return (
                                        <div key={index} className={`carousel-item ${styles.open_carousel_item} ${index === 0 ? " active" : " "}`}>
                                            {media.type === "image" && <img src={`https://kats-media.s3.us-west-2.amazonaws.com${media.path}`} alt="img" className="d-block w-100" />}
                                            {media.type === "video" && <video className={styles.video_carousel + " d-block w-100"} controls>
                                                <source src={`https://kats-media.s3.us-west-2.amazonaws.com${media.path}`} type="video/mp4" />
                                            </video>}
                                            <button type="button" className={styles.zoom_modal_btn + " btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                                <img src="assets/images/fullscreen-icon.png" alt="icon" className={styles.zoom_icon} />
                                            </button>
                                        </div>
                                    )
                                })}
                            </>}

                            {media.length === 0 && <>
                                <div className={styles.open_carousel_item + " carousel-item active"}>
                                    <img src="/assets/images/pipeline-yellow.png" alt="img" className="d-block w-100" />
                                    <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                        <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                    </button>
                                </div>
                                <div className={styles.open_carousel_item + " carousel-item"}>
                                    <img src="/assets/images/pipeline-yellow.png" alt="img" className="d-block w-100" />
                                    <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                        <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                    </button>
                                </div>
                                <div className={styles.open_carousel_item + " carousel-item"}>
                                    <video className={styles.video_carousel + " d-block w-100"} controls>
                                        <source src="assets/images/mov_bbb.mp4" type="video/mp4" />
                                    </video>
                                    <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                        <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                    </button>
                                </div>
                                <div className={styles.open_carousel_item + " carousel-item"}>
                                    <video className={styles.video_carousel + " d-block w-100"} controls>
                                        <source src="assets/images/mov_bbb.mp4" type="video/mp4" />
                                    </video>
                                    <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                        <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                    </button>
                                </div>
                                <div className={styles.open_carousel_item + " carousel-item"}>
                                    <img src="/assets/images/pipeline-yellow.png" alt="img" className="d-block w-100" />
                                    <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                        <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                    </button>
                                </div>
                            </>}

                        </div>
                    </div>
                </div>

                <div className={styles.details_pan}>
                    <ul className={styles.details_tab + " nav nav-tabs"}>
                        <li className={styles.details_tab_item + " nav-item"}>
                            <a className={styles.details_tab_link + " nav-link active"} aria-selected="true" data-bs-toggle="tab" href="#details">Details</a>
                        </li>
                        <li className="nav-item details_tab_item">
                            {/* {selecteditem.similaritemslist. &&  <a className="nav-link details_tab_link" data-bs-toggle="tab" href="#similer_problem">Similar Problems<span className="open_number_disabled">{selecteditem.similaritemslist.length < 10 ? "0" + selecteditem.similaritemslist.length : selecteditem.similaritemslist.length}</span></a>} */}
                        </li>
                    </ul>
                    <div className="tab-content">

                        <div className={styles.filter_container + " tab-pane fade show active"} id="details">

                            <div className={styles.location + " d-flex justify-content-start align-items-center"}>
                                <div className={styles.logo_location}>
                                    <img src="/assets/images/location-12.png" alt="icon" className={styles.location_icon_set} />
                                </div>
                                <div className={styles.location_name}>
                                    <p className={styles.location_head}>Location</p>
                                    <p className={styles.full_location}>{selecteditem.city_name}</p>
                                </div>
                            </div>

                            <div className={styles.description}>
                                <p className={styles.description_head}>Description</p>
                                {selecteditem.reply && <p className={styles.description_inner_para}>
                                    {selecteditem.reply.length > 195 ? <>
                                        {isTruncate ? selecteditem.reply.slice(0, 195) + '... ' : selecteditem.reply + " "}
                                        <button className={styles.plus + " " + styles.see_full_para} onClick={() => setIsTruncate(!isTruncate)}>
                                            {isTruncate ? 'Read More' : 'Read Less'}
                                        </button>
                                    </> : <>{selecteditem.reply}</>}
                                </p>}
                            </div>

                            {audio.length !== 0 && <>
                                <p className={styles.audio_head}>Play audio to hear issue</p>

                                {audio.map((audio: any, index: number) => {
                                    return (
                                        <div key={index} className={styles.audio_player + " audio_player d-flex justify-content-between align-items-center"}>
                                            <WaveForm url={audio.path} />
                                        </div>
                                    )
                                })}
                            </>}

                            <div className={styles.btn_response_set}>
                                <button type="button" className={styles.btn_response + " btn"} data-bs-backdrop="false" data-bs-toggle="offcanvas" data-bs-target="#offcanvas_submit_response">
                                    Respond
                                </button>
                            </div>
                        </div>

                        {selecteditem.similaritemslist && <div className={styles.filter_container + " tab-pane fade"} id="similer_problem">
                            <div className={styles.similer_fault_list}>
                                <ul className={styles.similer_problem_list}>

                                    {selecteditem.similaritemslist.map((items: any, index: any) => {
                                        return <li className={styles.similer_problem_list_item} key={index}>
                                            <SimilarProblemslist similaritem={items} />
                                        </li>
                                    })}

                                </ul>
                            </div>
                            <div className={styles.btn_response_set}>
                                <button className={styles.btn_response + " btn"}>Respond All Similar Problems</button>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>}

            {selecteditem.status === "closed" && <div className={styles.open_main_container}>
                <div className={styles.head_open_close + " d-flex justify-content-between align-items-center"}>
                    <span className={styles.head_open}>{selecteditem.status}</span>
                    <button className={styles.btn_close} onClick={() => handlecloseselecteditem()}>
                        <img src="/assets/images/close.png" alt="icon" />
                    </button>
                </div>

                <div className={styles.set_fault_head_open + " d-flex justify-content-start align-items-center"}>
                    <img src="/assets/images/Water-Leak.png" alt="icon" />
                    <div className={styles.fault_data}>
                        <p className={styles.fault_data_head_open}>{selecteditem.remark}</p>
                        <div className={styles.date_para}>
                            <img src="/assets/images/calender-icon.png" alt="icon" className={styles.date_icon_set} />
                            <span className={styles.fault_date}>{selecteditem.created_at}</span>
                            {/* <span className="problem_para">
                                {selecteditem.reporteditemtype === "Problem" && <img src="/assets/images/caution-icon.png" alt="icon" className="caution_icon_set" />}
                                {selecteditem.reporteditemtype === "Feedback" && <img src="/assets/images/assistant.png" alt="icon" className="caution_icon_set" />}
                                {selecteditem.reporteditemtype === "Answers" && <img src="/assets/images/quickreply.png" alt="icon" className="caution_icon_set" />}
                                {selecteditem.reporteditemtype}
                            </span> */}
                        </div>
                    </div>
                </div>

                <div className={styles.carousel_open}>
                    <p className={styles.head_report_carousel}>Reported photos & video</p>

                    <div id="pipeline_close_one" className="carousel slide" data-bs-ride="carousel">
                        <div className={styles.image_indicators +  " carousel-indicators image_indicators"}>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="0" aria-current="true" className={styles.image_indic_set + " active"}>
                                <img src="/assets/images/pipeline-one.jpeg" alt="img" className={styles.indic_image} />
                            </button>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="1" className={styles.image_indic_set}>
                                <img src="/assets/images/pipeline-black.jpeg" alt="img" className={styles.indic_image} />
                            </button>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="2" className={styles.image_indic_set}>
                                <img src="/assets/images/pipeline-black-video.png" alt="img" className={styles.indic_image} />
                            </button>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="3" className={styles.image_indic_set}>
                                <img src="/assets/images/pipeline-green.png" alt="img" className={styles.indic_image} />
                            </button>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="4" className={styles.image_indic_set}>
                                <img src="/assets/images/pipeline-plant.png" alt="img" className={styles.indic_image} />
                            </button>
                        </div>

                        <div className={styles.round_indicators_set + " carousel-indicators round_indicators_set"}>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="0" aria-current="true" className={styles.round_indicators + " active"}></button>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="1" className={styles.round_indicators}></button>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="2" className={styles.round_indicators}></button>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="3" className={styles.round_indicators}></button>
                            <button type="button" data-bs-target="#pipeline_close_one" data-bs-slide-to="4" className={styles.round_indicators}></button>
                        </div>

                        <div className="carousel-inner">
                            <div className={styles.open_carousel_item + " carousel-item active"}>
                                <img src="/assets/images/pipeline-yellow.png" alt="img" className="d-block w-100" />
                                <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                    <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                </button>
                            </div>
                            <div className={styles.open_carousel_item + " carousel-item"}>
                                <img src="/assets/images/pipeline-yellow.png" alt="img" className="d-block w-100" />
                                <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                    <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                </button>
                            </div>
                            <div className={styles.open_carousel_item + " carousel-item"}>
                                <video className={styles.video_carousel + " d-block w-100"} controls>
                                    <source src="/assets/images/mov_bbb.mp4" type="video/mp4" />
                                </video>
                                <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                    <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                </button>
                            </div>
                            <div className={styles.open_carousel_item + " carousel-item"}>
                                <video className={styles.video_carousel + " d-block w-100"} controls>
                                    <source src="assets/images/mov_bbb.mp4" type="video/mp4" />
                                </video>
                                <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                    <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                </button>
                            </div>
                            <div className={styles.open_carousel_item + " carousel-item"}>
                                <img src="/assets/images/pipeline-yellow.png" alt="img" className="d-block w-100" />
                                <button type="button" className={styles.zoom_modal_btn + " btn zoom_modal_btn"} data-bs-toggle="modal" data-bs-target="#zoom_image_modal">
                                    <img src="assets/images/fullscreen-icon.png" alt="icon" className="zoom_icon" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.details_pan}>
                    <p className={styles.response_head}>Response</p>
                    <p className={styles.response_para}>{selecteditem.reply}</p>
                </div>
            </div>}

        </>
    )

}