import React, { Component } from "react";
import WaveSurfer from "wavesurfer.js";
import "./waveForm.css"
import styles from "./waveForm.module.css"

interface WaveFormProps {
    url: string;
  }
  
export default class WaveForm extends React.Component<WaveFormProps> {

    constructor(props: any) {
        super(props);
    }

    waveform: any

    state = {
        duration: 0,
        url: `https://kats-media.s3.us-west-2.amazonaws.com${this.props.url}`
    };

    componentDidMount() {
        const track = document.querySelector("#track");

        this.waveform = WaveSurfer.create({
            barWidth: 1,
            barRadius: 3,
            barGap: 2,
            barMinHeight: 1,
            cursorWidth: 1,
            container: "#waveform",
            backend: "WebAudio",
            height: 32,
            progressColor: "#546EF3",
            responsive: true,
            waveColor: "#57667B",
            cursorColor: "transparent"
        });

        this.getAudioLength(this.state.url).then((duration) => {
            this.setState({ duration: duration });
        }).catch((err) => {
            console.log(err);
        })
        this.waveform.load(track);

    }

    handlePlay = () => {
        this.waveform.playPause();
        this.setState({ playing: this.waveform.isPlaying() });
    };


    getAudioLength = async (audioFile: any) => {
        const audioContext = new window.AudioContext;
        const response = await fetch(audioFile);
        const audioData = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(audioData);
        const audioDuration = audioBuffer.duration;
        const minutes = Math.floor(audioDuration / 60);
        const remainingSeconds = audioDuration % 60;
        const formattedSeconds = remainingSeconds < 9.5 ? `0${remainingSeconds.toFixed(0)}` : remainingSeconds.toFixed(0);
        const seconds = `${minutes} : ${formattedSeconds}`
        return seconds;
    };

    render() {

        const url = `https://kats-media.s3.us-west-2.amazonaws.com${this.props.url}`

        return (
            <div className={styles.waveformcontianer}>
                <button className={styles.btn_play_pause} onClick={this.handlePlay}>
                    {this.waveform && <>
                        {this.waveform.isPlaying() ? <img src="/assets/images/pause.png" alt="play" /> : <img src="/assets/images/play_arrow-sq.png" alt="play" />}
                    </>}
                </button>
                <div className={styles.wave + " " + styles.waveform} id="waveform" />
                <audio id="track" src={url} />
                <div className={styles.audio_time}>{this.state.duration}
                </div>
            </div>
        );
    }
}
