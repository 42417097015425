import React, { useEffect, useRef, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import ReportedItem from "../../components/Reuseablecomponents/reportedItem/reportedItem";
import chartdatalist from "../../utils/chartdatalists.json"
import Zoomimagemodal from "../../components/profileModals/zoomImageModal/zoomImageModal";
import Changepassword from "../../components/profileModals/changePassword/changePassword";
import Changepasswordsuccessmodal from "../../components/profileModals/changePasswordSuccessModal/changePasswordSuccessModal";
import Resposesubmittedmodal from "../../components/profileModals/resposeSubmittedModal/resposeSubmittedModal";
import Setpreferencemodal from "../../components/profileModals/setPreferenceModal/setPreferenceModal";
import $ from "jquery"
import Userprofilemodal from "../../components/profileModals/userProfileModal/userProfileModal";
import Editprofilemodal from "../../components/profileModals/editProfileModal/editProfileModal";
import Dashboardservices from "../../services/dashboardServices";
import { AllIssueModel } from "../../model/allIssueModel/allIssueModel";
import Reporteditemdetails from "../../components/Reuseablecomponents/reportedItemDetails/reportedItemDetails";
import GoogleMapComponent from "../../components/googleMapWrapper/googleMapWrapper";
import styles from "./homePage.module.css"
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store";
import { fetchSelectedCity } from "../../app-slice";
import axios from "axios";

interface reporteditemprops {
    id: string,
    itemname: string,
    reporteddate: string,
    reporteditemdescription: string,
    reporteditemimage: string,
    reporteditemstatus: string,
    reporteditemtype: string,
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function Homepage() {

    class dservice extends Dashboardservices { }

    const services = new dservice()
    const city = useSelector((state: RootState) => state.selectedCity.SelectedCity)
    const [selecteditem, setSelecteditem] = useState<any>()
    const [selecteditemtoggle, setSelecteditemtoggle] = useState(false)
    const [responseUploadedFiles, setResponseUploadedFiles] = useState<any>(null)
    const [allIssues, setAllIssues] = useState<Array<AllIssueModel>>()
    const [allCategories, setAllCategories] = useState<Array<any>>()
    const [filterDatabyTypeConfing, setFilterDatabyTypeConfing] = useState<Array<any>>([])
    const [filterDatabyDate, setFilterDatabyDate] = useState<any>()
    const filterDataFormRef = useRef<any>()
    const [issueResponseData, setIssueResponseData] = useState({
        response: "",
        itemStatus: "open"
    })
    const dispatch: AppDispatch = useDispatch()

    const handleSelectCity = (city: any) => {
        dispatch(fetchSelectedCity(city))
    }

    const [allCities, setAllCities] = useState<any>(null)

    const handleselecteditem = (id: any) => {

        if (selecteditem === null || selecteditem === undefined) {
            services.getIssue(id).then((res) => {
                setSelecteditem(res.data.request)
                setSelecteditemtoggle(true)
                if ($("#right_sidebar").hasClass("show") === false) {
                    $("#btn_drower_right").click()
                }
            }).catch((err) => console.log(err))
        }
        else {
            if (selecteditem.id !== id) {
                services.getIssue(id).then((res) => {
                    setSelecteditem(res.data.request)
                    setSelecteditemtoggle(true)
                    if ($("#right_sidebar").hasClass("show") === false) {
                        $("#btn_drower_right").click()
                    }
                }).catch((err) => console.log(err))
            }
        }

    }

    const handledeletereportitem = (id: any) => {
        if (allIssues !== undefined) {
            // const filteredPeople = allIssues.filter((item: any) => item.id !== id);
            // setAllIssues(filteredPeople)
            services.deleteIssue(id).then((res: any) => {
                if (res.status === 200) {
                    services.getAllIssues(city).then((res) => {
                        setAllIssues(res.data.requests)
                    }).catch((err) => {
                        console.log(err);
                    }).then(() => {
                        if ($("#right_sidebar").hasClass("show") === false) {
                            $("#btn_drower_right").click()
                        }
                    })
                }

            })
            if (selecteditem) {
                if (selecteditem.id === id) {
                    setSelecteditemtoggle(false)
                    setTimeout(() => {
                        setSelecteditem(null)
                    }, 600);
                }
            }
        }
    }

    const handleResponseMediaUpload = (e: any) => {
        var urls: any = [];
        var id = 1
        const files = Array.from(e.target.files);
        files.forEach((file: any) => {
            const reader = new FileReader();

            const fileURL = URL.createObjectURL(file);
            const data = {
                file_name: file.name,
                type: file.type.split("/")[0],
                size: file.size,
                extension: file.type.split("/")[1],
                request_id: selecteditem.id,
            }
            urls.push({ ["fileURL"]: fileURL, ["id"]: id, ["type"]: file.type })
            id++;
            const formData = new FormData();
            formData.append('file', file)
            reader.onload = function (event: any) {
                const fileData = event.target.result;
                services.uploadFile(data).then((res: any) => {
                    axios.put(`${res.data.url}`, fileData, {
                        headers: {
                            'Content-Type': 'application/octet-stream', // Set the content type as binary
                        },
                    }).then((response: any) => {
                        axios.post(process.env.REACT_APP_UPLOAD_FILE_COMPLETED as string, {
                            media_id: res.data.media.id
                        })
                    }).catch((err: any) => {
                        console.log(err);
                    })
                }).catch((err: any) => {
                    console.log(err);
                })
            };
            reader.readAsArrayBuffer(file);

        })
        setResponseUploadedFiles(urls)
    }

    const handleDeleteUploadedFile = (id: any) => {
        const filteredPeople = responseUploadedFiles.filter((item: reporteditemprops) => item.id !== id);
        if (filteredPeople.length === 0) {
            setResponseUploadedFiles(null)
        }
        else {
            setResponseUploadedFiles(filteredPeople)
        }
    }

    const handlecloseselecteditem = () => {
        setSelecteditemtoggle(false)
        setTimeout(() => {
            setSelecteditem(null)
        }, 600);
    }

    const updateIssue = () => {
        if (issueResponseData.response !== "" && selecteditem) {

            const data = {
                remark: issueResponseData.response,
                city_name: selecteditem.city_name,
                state_name: selecteditem.state_name,
                lat: selecteditem.lat,
                long: selecteditem.long,
            }
            services.updateIssue(selecteditem.id, data)
        }
    }

    useEffect(() => {

        let carouselIndicatorButtons = document.querySelectorAll('.round_indicators_set button')
        document.querySelectorAll('.carousel#pipeline_one').forEach((button) => {
            button.addEventListener('slid.bs.carousel', function () {
                let activeIndicator: any = document.querySelector('.image_indicators button.active')
                carouselIndicatorButtons?.forEach((button2) => {
                    button2.classList.remove('active')
                    button2.removeAttribute("aria-current")
                })
                document.querySelector('.round_indicators_set button[data-bs-slide-to="'
                    + activeIndicator.dataset.bsSlideTo + '"]')?.classList.add('active')
                document.querySelector('.round_indicators_set button[data-bs-slide-to="'
                    + activeIndicator.dataset.bsSlideTo + '"]')?.setAttribute("aria-current", "true")
            });
        });

        let carouselIndicatorButtonsone = document.querySelectorAll('.round_indicators_set button')
        document.querySelectorAll('.carousel#pipeline_close_one').forEach((button) => {
            button.addEventListener('slid.bs.carousel', function () {
                let activeIndicator: any = document.querySelector('.image_indicators button.active')
                carouselIndicatorButtonsone?.forEach((button2) => {
                    button2.classList.remove('active')
                    button2.removeAttribute("aria-current")

                })
                document.querySelector('.round_indicators_set button[data-bs-slide-to="'
                    + activeIndicator.dataset.bsSlideTo + '"]')?.classList.add('active')
                document.querySelector('.round_indicators_set button[data-bs-slide-to="'
                    + activeIndicator.dataset.bsSlideTo + '"]')?.setAttribute("aria-current", "true")
            });
        });

        if (selecteditem) {
            setIssueResponseData((prev: any) => {
                return { ...prev, itemStatus: selecteditem.status }
            })
        }

        if (selecteditem) {
            if (selecteditem.status === "open") {
                if ($("#open_tab_button").hasClass("active") === false) {
                    $("#open_tab_button").click()
                }
            }
            if (selecteditem.status === "closed") {
                if ($("#closed_tab_button").hasClass("active") === false) {
                    $("#closed_tab_button").click()
                }
            }
        }

    }, [selecteditem])

    useEffect(() => {

        if (city !== "Select city") {
            services.getAllIssues(city).then((res) => {
                setAllIssues(res.data.requests)
                const sortedIssues = res.data.requests.sort((a: any, b: any) => a.category_id - b.category_id);
                // setAllIssues(sortedIssues)
                setFilterDatabyTypeConfing([])
                filterDataFormRef.current.reset()
                if (selecteditem) {
                    handlecloseselecteditem()
                }
            }).catch((err) => {
                console.log(err);
            }).then(() => {
                if ($("#right_sidebar").hasClass("show") === false) {
                    $("#btn_drower_right").click()
                }
            })
        }

    }, [city])


    useEffect(() => {
        document.title = 'Home Page';

        const abc = localStorage.getItem("login")
        if (abc === null) {
            toast.success('Successfully Logged in! Select your assigned city to resolve reports.', {
                id: 'success1',
                className: "toast_successfully_login",
                duration: 2500,
            })
            localStorage.setItem("login", "yes")
        }

        services.getAllCities().then((res) => {
            setAllCities(res.data.cities)
        })

        services.getAllCategories().then((res) => {
            setAllCategories(res.data.categories);
        })

    }, [])

    const handleFilterDataFormChange = (e: any) => {
        if (e.target.type === "checkbox") {
            if (e.target.checked === true) {
                setFilterDatabyTypeConfing((prev: any) => {
                    if (prev.indexOf(e.target.name) === -1) {
                        prev.push(e.target.name)
                    }
                    return prev
                })
            }
            if (e.target.checked !== true) {
                setFilterDatabyTypeConfing((prev: any) => {
                    const abc = prev.filter((item: any) => item !== e.target.name)
                    return abc
                })
            }
        }
        if (e.target.type === "date") {
            if(e.target.value !== ""){
                setFilterDatabyDate((prev: any) => {
                    return { ...prev, [e.target.name]: e.target.value }
                })
            }
            else{
                setFilterDatabyDate(undefined)
            }            
        }
    }

    const handleFilterDataFormSubmit = (e: any) => {
        e.preventDefault()
        if (city !== "Select city") {
            if (filterDatabyTypeConfing.length !== 0) {
                services.getFilteredIssue({ city: city, ...filterDatabyDate, type: filterDatabyTypeConfing.join(",") }).then((res) => {
                    setAllIssues(res.data.requests)
                })
            }
            else{
                services.getFilteredIssue({ city: city, ...filterDatabyDate }).then((res) => {
                    setAllIssues(res.data.requests)
                })
            }
        }
    }

    const handleClearfilter = () => {
        if (city !== "Select city") {
            services.getAllIssues(city).then((res) => {
                setAllIssues(res.data.requests)
                const sortedIssues = res.data.requests.sort((a: any, b: any) => a.category_id - b.category_id);
                // setAllIssues(sortedIssues)

                if (selecteditem) {
                    handlecloseselecteditem()
                }
            }).catch((err) => {
                console.log(err);
            }).then(() => {
                if ($("#right_sidebar").hasClass("show") === false) {
                    $("#btn_drower_right").click()
                }
            })
        }
    }

    return (
        <>

            <div className={styles.wrapper}>

                <div className={styles.inner_wrapper}>
                    {/* Map  */}
                    <div className={styles.map}>
                        <div className={styles.mapouter}>
                            <div className={styles.gmap_canvas}>
                                <GoogleMapComponent allIssues={allIssues} selecteditem={selecteditem} handleselecteditem={(id: any) => handleselecteditem(id)} />
                            </div>
                        </div>
                    </div>

                    <div className={styles.map_content}>
                        <div className={`${styles.nav_search} d-flex justify-content-start align-items-center`}>
                            {/* <div className={styles.nav_logo}>
                                <img src="/assets/images/image-logo-nhma.png" alt="icon" />
                            </div> */}
                            <div className="dropdown sel_city_drop">
                                <button type="button" className={`${styles.btn_sel_city} dropdown-toggle`} data-bs-toggle="dropdown">
                                    <img src="/assets/images/location_on.png" alt="icon" />
                                    <span>{city}</span>
                                </button>
                                <ul className={`dropdown-menu ${styles.sel_city_drop_menu}`}>
                                    {/* <li>
                                        <div className={`${styles.set_city_link} dropdown-item ${city === "San Fransisco" ? `active  ${styles.set_city_link_active}` : ""}`} onClick={() => setCity("San Fransisco")}>
                                            <div className={`d-flex justify-content-between align-items-center`}>
                                                <div className={`${styles.city_link_set}`}>
                                                    <img src="/assets/images/location_on-blur.png" alt="icon" />
                                                    <span className={`${styles.city_name}`}>San Fransisco</span>
                                                </div>
                                                <img src="/assets/images/check_circle.png" alt="icon" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={`${styles.set_city_link} dropdown-item ${city === "Los Angeles" ? `active  ${styles.set_city_link_active}` : " "}`} onClick={() => setCity("Los Angeles")}>
                                            <div className={`d-flex justify-content-between align-items-center`}>
                                                <div className={`${styles.city_link_set}`}>
                                                    <img src="/assets/images/location_on-blur.png" alt="icon" />
                                                    <span className={`${styles.city_name}`}>Los Angeles</span>
                                                </div>
                                                <span className={`${styles.set_default}`}>Set As Default</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={`${styles.set_city_link} dropdown-item ${city === "Colorado" ? `active  ${styles.set_city_link_active}` : ""}`} onClick={() => setCity("Colorado")}>
                                            <div className={`d-flex justify-content-between align-items-center`}>
                                                <div className={`${styles.city_link_set}`}>
                                                    <img src="/assets/images/location_on-blur.png" alt="icon" />
                                                    <span className={`${styles.city_name}`}>Colorado</span>
                                                </div>
                                                <img src="/assets/images/check_circle.png" alt="icon" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={`${styles.set_city_link} dropdown-item ${city === "Detroit" ? `active  ${styles.set_city_link_active}` : " "}`} onClick={() => setCity("Detroit")}>
                                            <div className={`d-flex justify-content-between align-items-center`}>
                                                <div className={`${styles.city_link_set}`}>
                                                    <img src="/assets/images/location_on-blur.png" alt="icon" />
                                                    <span className={`${styles.city_name}`}>Detroit</span>
                                                </div>
                                                <img src="/assets/images/check_circle.png" alt="icon" />
                                            </div>
                                        </div>
                                    </li> */}

                                    {allCities && allCities.map((cities: any, index: any) => {
                                        return (<li key={index}>
                                            <div className={`${styles.set_city_link} dropdown-item ${city === cities.name ? `active  ${styles.set_city_link_active}` : ""}`} onClick={() => handleSelectCity(cities.name)}>
                                                <div className={`d-flex justify-content-between align-items-center`}>
                                                    <div className={`${styles.city_link_set}`}>
                                                        <img src="/assets/images/location_on-blur.png" alt="icon" />
                                                        <span className={`${styles.city_name}`}>{cities.name}</span>
                                                    </div>
                                                    <img src="/assets/images/check_circle.png" alt="icon" />
                                                </div>
                                            </div>
                                        </li>)
                                    })}

                                </ul>
                            </div>
                        </div>
                    </div>

                    {(city !== "Select city") && <div className={styles.set_right_drower_main}>
                        <button type="button" className={`btn ${styles.btn_drower_right}`} id="btn_drower_right" data-bs-toggle="offcanvas" data-bs-target="#right_sidebar">
                            <img src="/assets/images/keyboard_double_arrow_right.png" alt="icon" />
                        </button>
                        <div className={styles.drower_pan}></div>
                    </div>}

                    {/* All report  */}
                    <div className={`offcanvas offcanvas-end ${styles.offcanvas_right}`} data-bs-backdrop="false" id="right_sidebar">
                        <div className={`${styles.inner_all_report} d-flex justify-content-center align-items-center`}>

                            <button type="button" className={styles.btn_drower} data-bs-toggle="offcanvas" data-bs-target="#right_sidebar" >
                                <img src="assets/images/keyboard_double_arrow_right.png" alt="icon" />
                            </button>

                            <div className={styles.offcanvas_all_report}>
                                <div className={styles.set_heading_all_report + " d-flex justify-content-between align-items-center"}>
                                    <span className={styles.heading_all_report}>All reported</span>
                                    <button type="button" className={styles.user_profile_modal_btn + " btn"} data-bs-toggle="modal" data-bs-target="#user_profile_modal">
                                        <img src="assets/images/user_icon.png" alt="icon" />
                                    </button>
                                </div>
                                <div className={styles.report_data}>
                                    <div className={styles.report_data_tab}>
                                        <ul className={styles.report_tab + " nav nav-tabs"}>
                                            <li className={"nav-item " + styles.report_item}>
                                                {/* <a className="nav-link report_link active" data-bs-toggle="tab" href="#open">Open<span className="open_number_disabled">{reporteditemslistopen.length < 10 ? "0" + reporteditemslistopen.length : reporteditemslistopen.length}</span></a> */}
                                                <button className={styles.report_link + " nav-link active"} data-bs-toggle="tab" id="open_tab_button" data-bs-target="#open" aria-selected="true">Open
                                                    <span className={styles.open_number_disabled}>
                                                        {allIssues !== undefined && allIssues?.filter((item) => item.status === "open").length < 10 ? "0" + allIssues.filter((item) => item.status === "open").length : allIssues?.filter((item) => item.status === "open").length}
                                                    </span>
                                                </button>
                                            </li>
                                            <li className={"nav-item " + styles.report_item}>
                                                <button className={styles.report_link + " nav-link"} data-bs-toggle="tab" id="closed_tab_button" data-bs-target="#closed">Closed
                                                    <span className={styles.open_number_disabled}>
                                                        {allIssues !== undefined && allIssues?.filter((item) => item.status === "closed").length < 10 ? "0" + allIssues.filter((item) => item.status === "closed").length : allIssues?.filter((item) => item.status === "closed").length}
                                                    </span>
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className={styles.filter_container + " tab-pane fade show active"} id="open">
                                                <div className={styles.filter_header + " d-flex justify-content-between align-items-center"}>
                                                    <form className={styles.form_search}>
                                                        <div className={styles.search_group + " input-group"}>
                                                            <span className={styles.search_icon + " input-group-text"}>
                                                                <img src="/assets/images/search-icon.png" alt="icon" className={styles.search_icon_set} />
                                                            </span>
                                                            <input type="text" className={styles.search_input + " form-control"} placeholder="Search" />
                                                        </div>
                                                    </form>
                                                    <div className={styles.filter_dropdown + " dropdown"}>
                                                        <button type="button" className={styles.btn_filter + " btn"} data-bs-toggle="modal" data-bs-target="#filter_modal">
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path className={styles.filter_icon_bg} d="M6.1669 13.6668C5.93079 13.6668 5.73287 13.587 5.57315 13.4272C5.41342 13.2675 5.33356 13.0696 5.33356 12.8335V7.8335L0.50023 1.66683C0.291896 1.38905 0.260646 1.09739 0.40648 0.791829C0.552313 0.486274 0.805785 0.333496 1.1669 0.333496H12.8336C13.1947 0.333496 13.4481 0.486274 13.594 0.791829C13.7398 1.09739 13.7086 1.38905 13.5002 1.66683L8.6669 7.8335V12.8335C8.6669 13.0696 8.58704 13.2675 8.42731 13.4272C8.26759 13.587 8.06967 13.6668 7.83356 13.6668H6.1669Z" fill="#5D6D83" />
                                                            </svg>
                                                        </button>
                                                        <div className={styles.filter_modal_set + " modal fade"} data-bs-backdrop="false" id="filter_modal">
                                                            <div className={styles.filter_modal_dialogue + " modal-dialog"}>
                                                                <div className={styles.filter_modal_content + " modal-content"}>
                                                                    <div className={styles.filter_modal_header + " modal-header"}>
                                                                        <h4 className={styles.filter_title + " modal-title"}>Filter</h4>
                                                                        <button type="button" className={styles.btn_close_filter_modal + " btn-close"} data-bs-dismiss="modal"></button>
                                                                    </div>
                                                                    <div className={styles.filter_modal_body + " modal-body"}>
                                                                        <form onChange={(e: any) => handleFilterDataFormChange(e)} onSubmit={(e: any) => handleFilterDataFormSubmit(e)} ref={filterDataFormRef}>
                                                                            <div className={styles.filter_data}>
                                                                                <div className={styles.date_filter}>
                                                                                    <div className={styles.from}>
                                                                                        <label htmlFor="startDate" className={styles.date_label}>From</label>
                                                                                        <input type="date" name="startDate" id="startDate" className={styles.date_input} />
                                                                                    </div>
                                                                                    <div className={styles.to}>
                                                                                        <label htmlFor="dateto" className={styles.date_label}>To</label>
                                                                                        <input type="date" name="dateto" id="dateto" className={styles.date_input} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className={styles.by_type}>
                                                                                    <p className={styles.by_type_head}>By type</p>
                                                                                    <div className={styles.check_list}>
                                                                                        <div className={styles.check_input_set + " form-check"}>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="problem" name="problem" />
                                                                                            <label className={styles.check_label + " form-check-label"} htmlFor="problem">Problems</label>
                                                                                        </div>
                                                                                        <div className={styles.check_input_set + " form-check"}>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="feedback" name="feedback" />
                                                                                            <label className={styles.check_label + " form-check-label"} htmlFor="feedback">Feedbacks</label>
                                                                                        </div>
                                                                                        <div className={styles.check_input_set + " form-check"}>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="answer" name="answer" />
                                                                                            <label className={styles.check_label + " form-check-label"} htmlFor="answer">Answers</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={styles.by_categories}>
                                                                                    <p className={styles.by_type_head}>By Categories</p>
                                                                                    <div className={styles.check_list}>
                                                                                        <div className={styles.check_input_set + " form-check"}>
                                                                                            <div>
                                                                                                <input className={styles.check_input + " form-check-input"} type="checkbox" id="WaterLeakage" name="WaterLeakage" />
                                                                                            </div>
                                                                                            <label className={styles.check_label + " form-check-label"} htmlFor="WaterLeakage">Water Leakage</label>
                                                                                        </div>
                                                                                        <div className={styles.check_input_set + " form-check"}>
                                                                                            <div>
                                                                                                <input className={styles.check_input + " form-check-input"} type="checkbox" id="LitteronStreet" name="LitteronStreet" />
                                                                                            </div>
                                                                                            <label className={styles.check_label + " form-check-label"} htmlFor="LitteronStreet">Litter on Street</label>
                                                                                        </div>
                                                                                        <div className={styles.check_input_set + " form-check"}>
                                                                                            <div>
                                                                                                <input className={styles.check_input + " form-check-input"} type="checkbox" id="TrashOverflow" name="TrashOverflow" />
                                                                                            </div>
                                                                                            <label className={styles.check_label + " form-check-label"} htmlFor="TrashOverflow">Trash Overflow</label>
                                                                                        </div>
                                                                                        <div className={styles.check_input_set + " form-check"}>
                                                                                            <div>
                                                                                                <input className={styles.check_input + " form-check-input"} type="checkbox" id="BrokenWall" name="BrokenWall" />
                                                                                            </div>
                                                                                            <label className={styles.check_label + " form-check-label"} htmlFor="BrokenWall">Broken Wall</label>
                                                                                        </div>
                                                                                        <div className={styles.check_input_set + " form-check"}>
                                                                                            <div>
                                                                                                <input className={styles.check_input + " form-check-input"} type="checkbox" id="BrokenWall2" name="BrokenWall2" />
                                                                                            </div>
                                                                                            <label className={styles.check_label + " form-check-label"} htmlFor="BrokenWall2">Broken Wall</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={styles.buttons + " d-flex justify-content-between align-items-center"}>
                                                                                    <button className={styles.btn_clear_all} type="button" id="clearAllbutton" data-bs-dismiss="modal" onClick={() => {
                                                                                        if (filterDataFormRef.current !== null) {
                                                                                            filterDataFormRef.current.reset()
                                                                                            setFilterDatabyTypeConfing([])
                                                                                            setFilterDatabyDate(undefined)
                                                                                            handleClearfilter()
                                                                                        }
                                                                                    }}>Clear All</button>
                                                                                    <button id="closeFilterDataModal" type="button" data-bs-dismiss="modal" className="d-none">

                                                                                    </button>
                                                                                    <button className={styles.btn_show_result}>Show Results</button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.fault_list}>
                                                    {/* Nav tabs  */}
                                                    <ul className={styles.fault_list_nav}>

                                                        {allIssues && <>
                                                            {allIssues.map((item: any, index: any) => {
                                                                const category = allCategories && allCategories.find((category: any) => category.id === item.category_id);
                                                                if (item.status === "open") {
                                                                    return <li className={`fault_list_nav_item ${selecteditem && selecteditem.id === item.id ? "active" : " "}`} key={index}>
                                                                        <ReportedItem category={category} handleselecteditem={(id: any) => handleselecteditem(id)} handledeletereportitem={(id: any) => handledeletereportitem(id)} reporteditem={item} />
                                                                    </li>
                                                                }
                                                            })}
                                                        </>}

                                                    </ul>
                                                </div>
                                            </div>

                                            <div className={styles.filter_container + " tab-pane fade"} id="closed">
                                                <div className={styles.filter_header + " d-flex justify-content-between align-items-center"}>
                                                    <form className={styles.form_search}>
                                                        <div className={styles.search_group + " input-group"}>
                                                            <span className={styles.search_icon + " input-group-text"}>
                                                                <img src="/assets/images/search-icon.png" alt="icon" className={styles.search_icon_set} />
                                                            </span>
                                                            <input type="text" className={styles.search_input + " form-control"} placeholder="Search" name="search_input" />
                                                        </div>
                                                    </form>
                                                    <div className={styles.filter_dropdown + " dropdown"}>
                                                        <button type="button" className={styles.btn_filter + " btn"} data-bs-toggle="modal" data-bs-target="#filter_modal_two">
                                                            <div className={styles.filter_icon}>
                                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path className={styles.filter_icon_bg} d="M6.1669 13.6668C5.93079 13.6668 5.73287 13.587 5.57315 13.4272C5.41342 13.2675 5.33356 13.0696 5.33356 12.8335V7.8335L0.50023 1.66683C0.291896 1.38905 0.260646 1.09739 0.40648 0.791829C0.552313 0.486274 0.805785 0.333496 1.1669 0.333496H12.8336C13.1947 0.333496 13.4481 0.486274 13.594 0.791829C13.7398 1.09739 13.7086 1.38905 13.5002 1.66683L8.6669 7.8335V12.8335C8.6669 13.0696 8.58704 13.2675 8.42731 13.4272C8.26759 13.587 8.06967 13.6668 7.83356 13.6668H6.1669Z" fill="#5D6D83" />
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        <div className={styles.filter_modal_set + " modal fade"} data-bs-backdrop="false" id="filter_modal_two">
                                                            <div className={styles.filter_modal_dialogue + " modal-dialog"}>
                                                                <div className={styles.filter_modal_content + " modal-content"}>
                                                                    <div className={styles.filter_modal_header + " modal-header"}>
                                                                        <h4 className={styles.filter_title + " modal-title"}>Filter</h4>
                                                                        <button type="button" className={styles.btn_close_filter_modal + " btn-close"} data-bs-dismiss="modal"></button>
                                                                    </div>
                                                                    <div className={styles.filter_modal_body + " modal-body"}>
                                                                        <div className={styles.filter_data}>
                                                                            <div className={styles.date_filter}>
                                                                                <div className={styles.from}>
                                                                                    <label htmlFor="datefromtwo" className={styles.date_label}>From</label>
                                                                                    <input type="date" name="datefromtwo" id="datefromtwo" className={styles.date_input} />
                                                                                </div>
                                                                                <div className="to">
                                                                                    <label htmlFor="datetotwo" className={styles.date_label}>To</label>
                                                                                    <input type="date" name="datetotwo" id="datetotwo" className={styles.date_input} />
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.by_type}>
                                                                                <p className={styles.by_type_head}>By type</p>
                                                                                <div className={styles.check_list}>
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="Problemstwo" name="Problemstwo" defaultValue="Problems" />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="Problemstwo">Problems</label>
                                                                                    </div>
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="Feedbackstwo" name="Feedbackstwo" defaultValue="Feedbacks" defaultChecked />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="Feedbackstwo">Feedbacks</label>
                                                                                    </div>
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="Answerstwo" name="Answerstwo" defaultValue="Answers" />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="Answerstwo">Answers</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="by_categories">
                                                                                <p className="by_type_head">By Categories</p>
                                                                                <div className="check_list">
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="WaterLeakagetwo" name="WaterLeakagetwo" defaultValue="Water Leakage" />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="WaterLeakagetwo">Water Leakage</label>
                                                                                    </div>
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="LitteronStreettwo" name="LitteronStreettwo" defaultValue="Litter on Street" defaultChecked />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="LitteronStreettwo">Litter on Street</label>
                                                                                    </div>
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="TrashOverflowtwo" name="TrashOverflowtwo" defaultValue="Trash Overflow" defaultChecked />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="TrashOverflowtwo">Trash Overflow</label>
                                                                                    </div>
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="BrokenWalltwo" name="BrokenWalltwo" defaultValue="Broken Wall" />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="BrokenWalltwo">Broken Wall</label>
                                                                                    </div>
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="BrokenWall2two" name="BrokenWall2two" defaultValue="Broken Wall" />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="BrokenWall2two">Broken Wall</label>
                                                                                    </div>
                                                                                    <div className={styles.check_input_set + " form-check"}>
                                                                                        <div>
                                                                                            <input className={styles.check_input + " form-check-input"} type="checkbox" id="LitteronStreettwo" name="LitteronStreettwo" defaultValue="Litter on Street" />
                                                                                        </div>
                                                                                        <label className={styles.check_label + " form-check-label"} htmlFor="LitteronStreettwo">Litter on Street</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.buttons + " d-flex justify-content-between align-items-center"}>
                                                                                <button className={styles.btn_clear_all}>Clear All</button>
                                                                                <button className={styles.btn_show_result}>Show Results</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.fault_list}>
                                                    {/* Nav tabs  */}

                                                    <ul className={styles.fault_list_nav}>
                                                        {allIssues && <>
                                                            {allIssues.map((item: any, index: any) => {
                                                                const category = allCategories && allCategories.find((category: any) => category.id === item.category_id);
                                                                if (item.status == "closed") {
                                                                    return <li className={`fault_list_nav_item ${selecteditem && selecteditem.id === item.id ? "active" : " "}`} key={index}>
                                                                        <ReportedItem category={category} handleselecteditem={(id: any) => handleselecteditem(id)} handledeletereportitem={(id: any) => handledeletereportitem(id)} reporteditem={item} />
                                                                    </li>
                                                                }
                                                            })}
                                                        </>}

                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {selecteditem && <div className={`${selecteditemtoggle ? styles.reported_item_details : styles.reported_item_details_close}`}>

                                <Reporteditemdetails styles={styles} selecteditem={selecteditem} handlecloseselecteditem={() => handlecloseselecteditem()} />

                            </div>}
                        </div>
                    </div>

                    {/* Submit Your Response  */}
                    <div className={styles.offcanvas_submit_response_set + " offcanvas offcanvas-end"} data-bs-backdrop="false" id="offcanvas_submit_response">
                        {selecteditem && <div className={styles.submit_your_response + " d-flex justify-content-center align-items-center"}>
                            <button type="button" className={styles.btn_drower} data-bs-toggle="offcanvas" data-bs-target="#offcanvas_submit_response">
                                <img src="/assets/images/keyboard_double_arrow_right.png" alt="icon" />
                            </button>
                            <div className={styles.response_inner}>
                                <div className={styles.reponse_head + " d-flex justify-content-between align-items-center"}>
                                    <span className={styles.reponse_title}>Submit your response</span>
                                    <button className={styles.btn_close_reponse} data-bs-dismiss="offcanvas">
                                        <img src="/assets/images/close.png" alt="icon" />
                                    </button>
                                </div>
                                <div className={styles.set_fault_head_open + " d-flex justify-content-start align-items-center"}>
                                    <div className={styles.water_leak_bg}>
                                        <img src={selecteditem.reporteditemimage} alt="icon" />
                                    </div>
                                    <div className={styles.fault_data}>
                                        <p className={styles.fault_data_head_open}>{selecteditem.itemname}</p>
                                        <div className={styles.date_para}>
                                            <img src="/assets/images/calender-icon.png" alt="icon" className={styles.date_icon_set} />
                                            <span className={styles.fault_date}>{selecteditem.reporteddate}</span>
                                            <span className={styles.problem_para}>
                                                {selecteditem.reporteditemtype === "Problem" && <img src="/assets/images/caution-icon.png" alt="icon" className={styles.caution_icon_set} />}
                                                {selecteditem.reporteditemtype === "Feedback" && <img src="/assets/images/assistant.png" alt="icon" className={styles.caution_icon_set} />}
                                                {selecteditem.reporteditemtype === "Answers" && <img src="/assets/images/quickreply.png" alt="icon" className={styles.caution_icon_set} />}
                                                {selecteditem.reporteditemtype}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.write_your_response}>
                                    <div className="form-group">
                                        <label htmlFor="response" className={styles.response_heading}>Please write your response</label>
                                        <textarea onChange={(e) => {
                                            setIssueResponseData((prev: any) => {
                                                return { ...prev, [e.target.name]: e.target.value }
                                            })
                                        }} className={styles.reponse_text + " form-control"} rows={5} id="response" name="response" placeholder="Start writing here..."></textarea>
                                    </div>
                                    <div className={styles.upload_media}>

                                        <p className={styles.response_heading}>Upload Media</p>
                                        {responseUploadedFiles === null && <form className={styles.file_upload_form} onChange={(e) => handleResponseMediaUpload(e)} >
                                            <label htmlFor="response_image" className={styles.file_upload}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 20C2.16667 20 1.45833 19.7083 0.875 19.125C0.291667 18.5417 0 17.8333 0 17V3C0 2.16667 0.291667 1.45833 0.875 0.875C1.45833 0.291667 2.16667 0 3 0H17C17.8333 0 18.5417 0.291667 19.125 0.875C19.7083 1.45833 20 2.16667 20 3V17C20 17.8333 19.7083 18.5417 19.125 19.125C18.5417 19.7083 17.8333 20 17 20H3ZM3 18H17C17.2833 18 17.5208 17.9042 17.7125 17.7125C17.9042 17.5208 18 17.2833 18 17V3C18 2.71667 17.9042 2.47917 17.7125 2.2875C17.5208 2.09583 17.2833 2 17 2H3C2.71667 2 2.47917 2.09583 2.2875 2.2875C2.09583 2.47917 2 2.71667 2 3V17C2 17.2833 2.09583 17.5208 2.2875 17.7125C2.47917 17.9042 2.71667 18 3 18ZM4 16L8 12L9.8 13.775L12 11L16 16H4ZM6 8C5.45 8 4.97917 7.80417 4.5875 7.4125C4.19583 7.02083 4 6.55 4 6C4 5.45 4.19583 4.97917 4.5875 4.5875C4.97917 4.19583 5.45 4 6 4C6.55 4 7.02083 4.19583 7.4125 4.5875C7.80417 4.97917 8 5.45 8 6C8 6.55 7.80417 7.02083 7.4125 7.4125C7.02083 7.80417 6.55 8 6 8Z" fill="#5D6D83" />
                                                </svg>
                                                <p className={styles.media_file}>No media files attached</p>
                                                <span className={styles.tap_link}>Tap to Upload</span>
                                            </label>
                                            <input type="file" className={styles.input_doc_upload} name="response_image" id="response_image" multiple />
                                        </form>}
                                        {responseUploadedFiles && <div className={styles.uploaded_media + " d-flex justify-content-start"}>

                                            {responseUploadedFiles.map((file: any) => {
                                                return (<div className={styles.upload_data} key={file.id}>
                                                    {file.type.slice(0, 5) === "image" && <img src={file.fileURL} alt="img" />}
                                                    {file.type.slice(0, 5) === "video" && <video className="w-100 h-100" src={file.fileURL}>    </video>}
                                                    <button className={styles.btn_remove_img} onClick={(e) => handleDeleteUploadedFile(file.id)}>
                                                        <img src="/assets/images/x15.png" alt="icon" />
                                                    </button>
                                                </div>)
                                            })}
                                        </div>}

                                    </div>
                                    <div className={styles.update_status}>
                                        <p className={styles.response_heading}>Update status</p>
                                        <div className={styles.open_close + " d-flex justify-content-start align-items-center"}>
                                            <div className={`${styles.open_close_btn} ${issueResponseData.itemStatus === "open" ? ` active ${styles.open_close_btn_active}` : " "}`} onClick={() => {
                                                setIssueResponseData((prev: any) => {
                                                    return { ...prev, itemStatus: "open" }
                                                })
                                            }}>
                                                {issueResponseData.itemStatus === "open" && <img src="/assets/images/check.png" alt="icon" />}
                                                <span>Open</span>
                                            </div>
                                            <div className={`${styles.open_close_btn} ${issueResponseData.itemStatus === "closed" ? ` active ${styles.open_close_btn_active}` : " "}`} onClick={() => {
                                                setIssueResponseData((prev: any) => {
                                                    return { ...prev, itemStatus: "closed" }
                                                })
                                            }}>
                                                {issueResponseData.itemStatus === "closed" && <img src="/assets/images/check.png" alt="icon" />}
                                                <span>Closed</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.submit_reponse_btn_container}>
                                        <button type="button" className={styles.submit_reponse_btn + " btn"}
                                            onClick={() => updateIssue()}
                                            data-bs-toggle="modal" data-bs-target="#respose_submitted_modal"
                                            disabled={!responseUploadedFiles}>
                                            Submit Response
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>

                    {/* Chart data  */}

                    <div className={styles.show_reults_chart_data}>
                        <div className={styles.set_chart_data}>
                            <button type="button" className={styles.btn_chart + " btn"} data-bs-toggle="modal" data-bs-target="#chart_data">
                                <img src="/assets/images/table_chart_view-btn.png" alt="icon" />
                                <span className={styles.btn_chart_head}>Show Analytics</span>
                            </button>
                            <div className={styles.chart_data_modal + " modal fade"} data-bs-backdrop="false" id="chart_data">
                                <div className={styles.chart_data_dialogue + " modal-dialog"}>
                                    <div className={styles.chart_data_content + " modal-content"}>
                                        <div className={styles.chart_data_header + " modal-header"}>
                                            <h4 className={styles.chart_data_title + " modal-title"}>General Analytics</h4>
                                            <button type="button" className={styles.btn_close_chart_data + " btn-close"} data-bs-dismiss="modal">
                                                <img src="assets/images/close.png" alt="icon" />
                                            </button>
                                        </div>
                                        <div className={styles.chart_data_body + " modal-body"}>
                                            <div className={styles.charts + " d-flex justify-content-center align-items-center"}>
                                                <div className={styles.report_card_chart}>
                                                    <div className={styles.chart_data}>
                                                        <p className={styles.head_chart}>Total reports</p>
                                                        <p className={styles.chart_number}>12,467</p>
                                                        <p className={styles.date_set}>Till 21, March 2023</p>
                                                    </div>
                                                    <Bar options={chartdatalist.chartdatalists.optionsone} data={chartdatalist.chartdatalists.dataone} />
                                                </div>
                                                <div className={styles.report_card_chart}>
                                                    <div className={styles.chart_data}>
                                                        <p className={styles.head_chart}>Total completed </p>
                                                        <p className={styles.chart_number}>9,789</p>
                                                        <p className={styles.date_set}>Till 21, March 2023</p>
                                                    </div>
                                                    <Bar options={chartdatalist.chartdatalists.optionstwo} data={chartdatalist.chartdatalists.datatwo} />
                                                </div>
                                                <div className={styles.report_card_chart}>
                                                    <div className={styles.chart_data}>
                                                        <p className={styles.head_chart}>Total open</p>
                                                        <p className={styles.chart_number}>2678</p>
                                                        <p className={styles.date_set}>Till 21, March 2023</p>
                                                    </div>
                                                    <Bar options={chartdatalist.chartdatalists.optionsthree} data={chartdatalist.chartdatalists.datathree} />
                                                </div>
                                            </div>
                                            <div className={styles.report_issued}>
                                                <p className={styles.heading_report_issue}>Highest number of issues reported by</p>
                                                <div className={styles.address_cards + " d-flex justify-content-center align-items-center"}>
                                                    <div className={styles.city_card}>
                                                        <div className={styles.city_address}>
                                                            <p className={styles.city_title}>City</p>
                                                            <p className={styles.city_name_chart}>San Francisco</p>
                                                        </div>
                                                        <p className={styles.report_count}>4,256</p>
                                                    </div>
                                                    <div className={styles.city_card}>
                                                        <div className={styles.city_address}>
                                                            <p className={styles.city_title}>Area</p>
                                                            <p className={styles.city_name_chart}>Pine Street</p>
                                                        </div>
                                                        <p className={styles.report_count}>256</p>
                                                    </div>
                                                    <div className={styles.city_card}>
                                                        <div className={styles.city_address}>
                                                            <p className={styles.city_title}>Place</p>
                                                            <p className={styles.city_name_chart}>Pine Street, X road</p>
                                                        </div>
                                                        <p className={styles.report_count}>34</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Zoom image modal  */}

                    {selecteditem && <Zoomimagemodal selectedItem={selecteditem} />}

                    {/* Edit Profile Modal 14-06-2023  */}

                    <Userprofilemodal styles={styles} />

                    {/* Change profile modal 14-06-2023  */}

                    <Editprofilemodal styles={styles} />

                    {/* Change password modal 14-06-2023  */}

                    <Changepassword styles={styles} />

                    {/* Change password successfully modal 14-06-2023  */}

                    <Changepasswordsuccessmodal styles={styles} />

                    {/* Change password successfully modal 14-06-2023  */}

                    <Resposesubmittedmodal styles={styles} />

                    {/* Set preference modal modal 14-06-2023  */}

                    <Setpreferencemodal styles={styles} />

                </div>

            </div>
        </>
    )

}
