import React, { useState } from "react"
import "./forgotPasswordPage.css"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function Forgotpasswordpage() {

    const Navigate = useNavigate()
    const [isSubmitting, setisSubmitting] = useState(false);

    const validationSchema = Yup.object().shape({
        emaillogin: Yup.string().required('Email is required').email('Email is invalid'),
    });

    const login = useFormik({
        initialValues: {
            emaillogin: 'john@domain.com',
        },
        validationSchema,
        onSubmit: (data: any) => {
            setisSubmitting(true)
            return new Promise((resolve) => {
                setTimeout(() => {
                    setisSubmitting(false);
                    resolve(undefined);
                    Navigate("/homepage")
                }, 3000)
            })
        },
    });

    return (
        <>
            <div className="fogetpassword_container">
                <div className="upper_part">
                    <div className="heading_left_forget d-flex justify-content-center align-items-center">
                        <img src="assets/images/cat-Vector.png" alt="icon" className="cat_icon" />
                        <p className="head">Kyurious<span className="inner_head">Kat</span></p>
                    </div>
                    <div className="below_img d-flex justify-content-center align-items-center">
                        <img src="assets/images/right-part-img.png" alt="icon" className="below_img_footer" />
                        <img src="assets/images/right-part-img.png" alt="icon" className="below_img_footer" />
                    </div>
                </div>
                <div className="bottom_part d-flex justify-content-center align-items-center">
                    <div className="set_bottom_part">
                        <div className="forget_psw_card">
                            <h3 className="head_forget_psw">Forgot Password?</h3>
                            <p className="para_forget_psw">No worries, we’ll send you reset instructions.</p>
                            <form onSubmit={login.handleSubmit}>

                                <div className="email_set_forget">
                                    <label htmlFor="emaillogin" className="form-label input_label">Email</label>
                                    <input id="emaillogin" type="email" name="emaillogin" defaultValue={login.values.emaillogin} className={'form-control input_set' +
                                        (login.errors.emaillogin && login.touched.emaillogin ? ' is-invalid' : '')
                                    } onChange={login.handleChange} placeholder="Enter email" />
                                    <div className="invalid-feedback" >
                                        {login.errors.emaillogin && login.touched.emaillogin
                                            ? login.errors.emaillogin
                                            : null}
                                    </div>
                                </div>
                                <button className="btn btn_login">Reset Password</button>
                            </form>
                        </div>
                        <Link to="/login" className="back_to_login_link"><img src="assets/images/arrow-forward-left.png" alt="icon" />Back to Login</Link>
                    </div>
                </div>
            </div>
        </>
    )

}