import React from "react";

export default function Userprofilemodal( {styles} : any ) {

    return (
        <>
            <div className="modal fade" id="user_profile_modal">
                <div className={styles.user_profile_modal_dialog + " modal-dialog modal-dialog-centered"}>
                    <div className={styles.user_profile_modal_content + " modal-content"}>
                        <div className={styles.user_profile_modal_header + " modal-header"}>
                            <button type="button" className={styles.close_modal_edit_profile_btn + " btn-close"} data-bs-dismiss="modal">
                                <img src="assets/images/close.png" alt="icon" />
                            </button>
                            <div className={styles.images_bg + " d-flex justify-content-center align-items-center"}>
                                <img src="/assets/images/edit-profile-header-bg1.png" alt="img" className={styles.edit_profile_bg_img_one} />
                                <img src="/assets/images/edit-profile-header-bg2.png" alt="img" className={styles.edit_profile_bg_img_two} />
                                <img src="/assets/images/edit-profile-header-bg3.png" alt="img" className={styles.edit_profile_bg_img_three} />
                                <img src="/assets/images/edit-profile-header-bg4.png" alt="img" className={styles.edit_profile_bg_img_four} />
                            </div>
                        </div>
                        <div className={" modal-body " + styles.user_profile_modal_body}>
                            <div className={styles.user_logo_edit_profile}>
                                <img src="/assets/images/user-logo-96.png" alt="img" className={styles.user_profile_pic} />
                            </div>
                            <p className={styles.link_edit_set}>
                                <button type="button" className={styles.edit_profile_link + " btn"} data-bs-toggle="modal" data-bs-target="#edit_profile_modal">
                                    Edit Profile
                                </button>
                            </p>
                            <p className={styles.user_name}>John Markers</p>
                            <div className={styles.email_set}>
                                <p className={styles.label_email}>Email</p>
                                <p className={styles.email}>Johnmarkers22@gmail.com</p>
                            </div>
                            <div className={styles.password_set}>
                                <p className={styles.label_email}>Password</p>
                                <p className={styles.password + " d-flex justify-content-start align-items-center"}>
                                    <span className={styles.password_dots}></span>
                                    <span className={styles.password_dots}></span>
                                    <span className={styles.password_dots}></span>
                                    <span className={styles.password_dots}></span>
                                    <span className={styles.password_dots}></span>
                                    <span className={styles.password_dots}></span>
                                    <span className={styles.password_dots}></span>
                                    <span className={styles.password_dots}></span>
                                </p>
                            </div>
                            <div className={styles.preference}>
                                <div className={styles.head_preference + " d-flex justify-content-between align-items-center"}>
                                    <span className={styles.showing_report}>Preferences for showing reports</span>
                                    <button type="button" className={styles.set_preference_link + " btn"} data-bs-toggle="modal" data-bs-target="#set_preference_modal">
                                        Set Preferences
                                    </button>
                                </div>
                                <div className={styles.problem_and_time}>
                                    <span className={styles.problem_name_set_pref}>Water Leak</span>
                                    <span className={styles.time_passed}>2 weeks old</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}