import React from "react";

export default function Setpreferencemodal({ styles }: any) {

    return (
        <>
            <div className="modal fade" id="set_preference_modal">
                <div className={styles.edit_profile_modal_dialog + " modal-dialog modal-dialog-centered"}>
                    <div className={styles.edit_profile_modal_content + " modal-content"}>
                        <div className={styles.edit_profile_modal_header + " modal-header"}>
                            <div className={styles.edit_profile}>
                                <p className={styles.head_edit_profile}>Set Preferences</p>
                                <p className={styles.head_edit_para}>Preferences to see reports by category</p>
                            </div>
                            <button type="button" className={styles.edit_profile_modal_btn + " btn-close"} data-bs-dismiss="modal">
                                <img src="assets/images/close.png" alt="icon" />
                            </button>
                        </div>
                        <div className={styles.edit_profile_modal_body + " modal-body"}>
                            <div className={styles.category_view_report}>
                                <div className={styles.head_category + " d-flex justify-content-between align-items-center"}>
                                    <span className={styles.left_head_category}>Choose category to view reports</span>
                                    <span className={styles.reset_link}>Reset</span>
                                </div>
                                <div className={styles.categories_of_report + " d-flex justify-content-start align-items-center"}>
                                    <span className={"badge " + styles.category_name + " " + styles.active}>Water Leak
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.26732 9.66634L0.333984 8.73301L4.06732 4.99967L0.333984 1.26634L1.26732 0.333008L5.00065 4.06634L8.73398 0.333008L9.66732 1.26634L5.93398 4.99967L9.66732 8.73301L8.73398 9.66634L5.00065 5.93301L1.26732 9.66634Z" fill="#546EF3" />
                                        </svg>
                                    </span>
                                    <span className={"badge " + styles.category_name }>Litter
                                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.36641 9.00007L0.566406 5.20007L1.51641 4.25007L4.36641 7.10007L10.4831 0.983398L11.4331 1.9334L4.36641 9.00007Z" fill="#5D6D83" />
                                        </svg>
                                    </span>
                                    <span className={"badge " + styles.category_name }>Accident
                                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.36641 9.00007L0.566406 5.20007L1.51641 4.25007L4.36641 7.10007L10.4831 0.983398L11.4331 1.9334L4.36641 9.00007Z" fill="#5D6D83" />
                                        </svg>
                                    </span>
                                    <span className={"badge " + styles.category_name }>Road Repair
                                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.36641 9.00007L0.566406 5.20007L1.51641 4.25007L4.36641 7.10007L10.4831 0.983398L11.4331 1.9334L4.36641 9.00007Z" fill="#5D6D83" />
                                        </svg>
                                    </span>
                                    <span className={"badge " + styles.category_name }>Fallen Branches
                                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.36641 9.00007L0.566406 5.20007L1.51641 4.25007L4.36641 7.10007L10.4831 0.983398L11.4331 1.9334L4.36641 9.00007Z" fill="#5D6D83" />
                                        </svg>
                                    </span>
                                    <span className={"badge " + styles.category_name }>Street Lights<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.26732 9.66634L0.333984 8.73301L4.06732 4.99967L0.333984 1.26634L1.26732 0.333008L5.00065 4.06634L8.73398 0.333008L9.66732 1.26634L5.93398 4.99967L9.66732 8.73301L8.73398 9.66634L5.00065 5.93301L1.26732 9.66634Z" fill="#57667B" />
                                    </svg>
                                    </span>
                                    <span className={"badge " + styles.category_name }>Accident<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.26732 9.66634L0.333984 8.73301L4.06732 4.99967L0.333984 1.26634L1.26732 0.333008L5.00065 4.06634L8.73398 0.333008L9.66732 1.26634L5.93398 4.99967L9.66732 8.73301L8.73398 9.66634L5.00065 5.93301L1.26732 9.66634Z" fill="#57667B" />
                                    </svg>
                                    </span>
                                    <span className={"badge " + styles.category_name }>Report to Local Authorities<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.26732 9.66634L0.333984 8.73301L4.06732 4.99967L0.333984 1.26634L1.26732 0.333008L5.00065 4.06634L8.73398 0.333008L9.66732 1.26634L5.93398 4.99967L9.66732 8.73301L8.73398 9.66634L5.00065 5.93301L1.26732 9.66634Z" fill="#57667B" />
                                    </svg>
                                    </span>
                                    <span className={"badge " + styles.category_name }>New Construction<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.36641 9.00007L0.566406 5.20007L1.51641 4.25007L4.36641 7.10007L10.4831 0.983398L11.4331 1.9334L4.36641 9.00007Z" fill="#5D6D83" />
                                    </svg>
                                    </span>
                                </div>
                            </div>
                            <div className={styles.show_data_input}>
                                <label className={styles.input_label}>Show data</label>
                                <select className={styles.show_data + " form-select"} onChange={(e: any) => { }} >
                                    <option value={"7days"}>1 Weeks old</option>
                                    <option>2 Weeks old</option>
                                </select>
                            </div>
                            <button type="submit" className={styles.btn_save + " btn"} data-bs-dismiss="modal" disabled>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}