import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './pages/homePage/homePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import Loginpage from './pages/loginPage/loginPage';
import Forgotpasswordpage from './pages/forgotPasswordPage/forgotPasswordPage';
import { Toaster, toast } from 'react-hot-toast';

function App() {

    return (
        <>

            <Toaster position="top-center" />

            <BrowserRouter>
                <Routes>
                    <Route index element={<Loginpage />} />
                    <Route path='login' element={<Loginpage />} />
                    <Route path='forgetpassword' element={<Forgotpasswordpage />} />
                    <Route path='homepage' element={<Homepage />} />
                </Routes>
            </BrowserRouter>

        </>
    );
}

export default App;
