import React from "react";

export default function Changepasswordsuccessmodal({ styles }: any) {

    return (
        <>
            <div className="modal fade" id="change_password_success_modal">
                <div className={styles.change_password_success_dialog + " modal-dialog modal-dialog-centered"}>
                    <div className={styles.edit_profile_modal_content + " modal-content"}>
                        <div className={styles.change_password_success_modal_body + " modal-body"}>
                            <img src="/assets/images/check-circle-green.png" alt="icon" />
                            <p className={styles.msg_password_changed}>Password changed!</p>
                            <p className={styles.msg_para_success}>Your password has been changed successfully</p>
                            <button type="button" className={styles.btn_okay + " btn"} data-bs-dismiss="modal">Okay!</button>
                        </div>
                        <div className={styles.change_password_success_modal_footer}>
                            <img src="/assets/images/password-changed_successfully.png" alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}