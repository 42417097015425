import React, { useEffect, useState } from "react"
import "./loginPage.css"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Dashboardservices from "../../services/dashboardServices";
import { AppDispatch, RootState } from "../../Store";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedCity } from "../../app-slice";

export default function Loginpage() {

    class dservice extends Dashboardservices { }
    const Navigate = useNavigate()
    const [isSubmitting, setisSubmitting] = useState(false);
    const services = new dservice()
    const [allCities, setAllCities] = useState<any>(null)
    const dispatch: AppDispatch = useDispatch()
    const city = useSelector((state: RootState) => state.selectedCity.SelectedCity)    

    const validationSchema = Yup.object().shape({
        emaillogin: Yup.string().required('Email is required').email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 40 characters')
    });

    useEffect(() => {
        document.title = 'Login Page';
        services.getAllCities().then((res) => {
            setAllCities(res.data.cities)
        })
    }, [])

    const login = useFormik({
        initialValues: {
            emaillogin: 'john@domain.com',
            password: '4948@Dhruv',
        },
        validationSchema,
        onSubmit: (data: any) => {
            setisSubmitting(true)
            return new Promise((resolve) => {
                setTimeout(() => {
                    setisSubmitting(false);
                    resolve(undefined);
                    Navigate("/homepage")
                    localStorage.removeItem("login")
                }, 3000)
            })
        },
    });
    
    const handleSelectCity = (city: any) => {
        dispatch(fetchSelectedCity(city))
        Navigate("/homepage")
    }

    return (
        <>
            <div className="loginpage_container d-flex justify-content-center align-items-center">
                <div className="left_part">
                    <div className="set_left_part">
                        <div className="heading_left_part d-flex justify-content-center align-items-center">
                            <img src="assets/images/cat-Vector.png" alt="icon" className="cat_icon" />
                            <p className="head">Kyurious<span className="inner_head">Kat</span></p>
                        </div>
                        <div className="problem_card">
                            <div className="fault_list_nav_login_link_login">
                                <div className="head_fault d-flex justify-content-between align-items-center">
                                    <div className="set_fault_head d-flex justify-content-start align-items-center">
                                        <div className="litter_bg">
                                            <img src="assets/images/Litter-bg.png" alt="icon" />
                                        </div>
                                        <div className="fault_data">
                                            <p className="fault_data_head">Litter spilled</p>
                                            <div className="date_para">
                                                <img src="/assets/images/calender-icon.png" alt="icon" className="date_icon_set" />
                                                <span className="fault_date">21,March 2023</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn_three_dot_set">
                                        <img src="/assets/images/three-dot.png" alt="icon" className="three_dot_icon_set" />
                                    </button>
                                </div>
                                <p className="fault_details">Noticed a significant increase in the amount of litter scattered throughout the park grounds.</p>
                                <div className="problem_para">
                                    <img src="/assets/images/caution-icon.png" alt="icon" className="caution_icon_set" />Problem
                                </div>
                            </div>
                        </div>
                        <div className="map_data_set">
                            <div className="map_data_card">
                                <img src="/assets/images/pipe3154x.png" alt="img" className="pipe_img" />
                                <div className="head_fault_prob d-flex justify-content-between align-items-center">
                                    <div className="set_fault_head d-flex justify-content-start align-items-center">
                                        <div className="litter_bg">
                                            <img src="/assets/images/Water-Leak-bg.png" alt="icon" />
                                        </div>
                                        <div className="fault_data">
                                            <p className="fault_data_head">Broken water pipeline</p>
                                            <div className="date_para">
                                                <img src="/assets/images/calender-icon.png" alt="icon" className="date_icon_set" />
                                                <span className="fault_date">21,March 2023</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn_arrow_forword">
                                        <img src="/assets/images/arrow_forward.png" alt="icon" />
                                    </button>
                                </div>
                            </div>
                            <img src="assets/images/map-pin-icon.png" alt="icon" className="map_pin" />
                            <img src="assets/images/round-fill.png" alt="img" className="round_fill_one" />
                            <img src="assets/images/round-fill.png" alt="img" className="round_fill_two" />
                        </div>
                        <div id="problem_details_carousel" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators indicators_set">
                                <button type="button" data-bs-target="#problem_details_carousel" data-bs-slide-to="0" className="round_indicat active"></button>
                                <button type="button" data-bs-target="#problem_details_carousel" data-bs-slide-to="1" className="round_indicat"></button>
                                <button type="button" data-bs-target="#problem_details_carousel" data-bs-slide-to="2" className="round_indicat"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <p className="problem_para_head">See problems and provide solution one</p>
                                    <p className="problem_sub_para">Find accurate location & details</p>
                                </div>
                                <div className="carousel-item">
                                    <p className="problem_para_head">See problems and provide solution two</p>
                                    <p className="problem_sub_para">Find accurate location & details</p>
                                </div>
                                <div className="carousel-item">
                                    <p className="problem_para_head">See problems and provide solution three</p>
                                    <p className="problem_sub_para">Find accurate location & details</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right_part">
                    <div className="login_card">
                        {/* <img src="assets/images/nhma4x.png" alt="img" className="nhma_img" /> */}
                        <h3 className="welcome_back_msg">Welcome back!</h3>
                        <p className="solution_para">Start looking at problem and provide solutions by selecting city from below dropdown</p>

                        {/* <form onSubmit={login.handleSubmit}>

                            <div className="email_set">
                                <label htmlFor="emaillogin" className="form-label input_label">Email</label>
                                <input id="emaillogin" type="email" name="emaillogin" defaultValue={login.values.emaillogin} className={'form-control input_set' +
                                    (login.errors.emaillogin && login.touched.emaillogin ? ' is-invalid' : '')
                                } onChange={login.handleChange} placeholder="Enter email" />
                                <div className="invalid-feedback" >
                                    {login.errors.emaillogin && login.touched.emaillogin
                                        ? login.errors.emaillogin
                                        : null}
                                </div>
                            </div>

                            <div className="password_set">
                                <label htmlFor="pwdlogin" className="form-label input_label">Password</label>
                                <input type="password" id="password" name="password" className={'form-control input_set_pass' +
                                    (login.errors.password && login.touched.password
                                        ? ' is-invalid'
                                        : '')
                                } onChange={login.handleChange} defaultValue={login.values.password} placeholder="Enter your password" />
                                <div className="invalid-feedback">
                                    {login.errors.password && login.touched.password
                                        ? login.errors.password
                                        : null}
                                </div>
                            </div>

                            <button type="submit" disabled={isSubmitting} className="btn btn_login d-flex justify-content-center gap-2 align-items-center">  {isSubmitting && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )} Login</button>

                        </form>

                        <Link to="/forgetpassword" className="link_forget_password">Forget Password</Link> */}

                        <div className="dropdown sel_city_drop">
                            <button type="button" className={`btn_sel_city dropdown-toggle`} data-bs-toggle="dropdown">
                                <img src="/assets/images/location_on.png" alt="icon" />
                                <span>{city}</span>
                            </button>
                            <ul className={`dropdown-menu sel_city_drop_menu`}>

                                {allCities && allCities.map((cities: any, index: any) => {
                                    return (<li key={index}>
                                        <div className={`set_city_link dropdown-item ${city === cities.name ? `active  set_city_link_active` : ""}`} onClick={() => handleSelectCity(cities.name)}>
                                            <div className={`d-flex justify-content-between align-items-center`}>
                                                <div className={`city_link_set`}>
                                                    <img src="/assets/images/location_on-blur.png" alt="icon" />
                                                    <span className={`city_name`}>{cities.name}</span>
                                                </div>
                                                <img src="/assets/images/check_circle.png" alt="icon" />
                                            </div>
                                        </div>
                                    </li>)
                                })}

                            </ul>
                        </div>

                    </div>

                    <div className="footer_img">
                        <img src="assets/images/right-part-img.png" alt="icon" className="img_footer" />
                    </div>
                </div>
            </div>
        </>
    )

}