import React from "react";
import "./similarProblemslist.css"

export default function SimilarProblemslist({ similaritem }: any) {

    return (
        <>
            <div className="problem_head d-flex justify-content-between align-items-center">
                <div className="problem_name d-flex justify-content-start align-items-center">
                    <div className="water_leak_bg">
                        <img src="/assets/images/Water-Leak-bg.png" alt="icon" />
                    </div>
                    <div className="problem_data">
                        <p className="fault_data_head">
                            {similaritem.itemname}
                        </p>
                        <div className="date_para">
                            <img src="/assets/images/calender-icon.png" alt="icon" className="date_icon_set" />
                            <span className="fault_date">
                                {similaritem.reporteddate}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="dropdown delete_item_drop_down">
                    <button type="button" className="btn btn_three_dot_set dropdown-toggle" data-bs-toggle="dropdown">
                        <img src="/assets/images/three-dot.png" alt="icon" className="three_dot_icon_set" />
                    </button>
                    <ul className="dropdown-menu btn_three_dot_dropdown_menu">
                        <li>
                            <div className="dropdown-item btn_three_dot_dropdown_menu_item">
                                <img src="/assets/images/delete-icon.png" alt="icon" />Delete
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="problem_para">
                {similaritem.reporteditemtype === "Problem" && <img src="/assets/images/caution-icon.png" alt="icon" className="caution_icon_set" />}
                {similaritem.reporteditemtype === "Feedback" && <img src="/assets/images/assistant.png" alt="icon" className="caution_icon_set" />}
                {similaritem.reporteditemtype === "Answers" && <img src="/assets/images/quickreply.png" alt="icon" className="caution_icon_set" />}
                {similaritem.reporteditemtype}
            </div>
        </>
    )

}