import axios from "axios";
import qs from 'qs';

export default class Dashboardservices {

    constructor() { }

    async getAllIssues(city: any) {

        return axios.get(`${process.env.REACT_APP_GET_ALL_ISSUE}`, {
            params: {
                status: "all",
                city: city
            }
        })

    }

    async getFilteredIssue(data: any) {
        console.log(data);
        
        return axios.get(`${process.env.REACT_APP_GET_ALL_ISSUE}`, {
            params: {
                status: "all",
                ...data
            }
        })

    }

    deleteIssue = (id: any) => {
        return axios.delete(`${process.env.REACT_APP_DELETE_ISSUES_API}/${id}`)
    }

    updateIssue = async (id: any, data: any) => {
        return axios.put(`${process.env.REACT_APP_GET_ISSUE}/${id}`, new URLSearchParams(data), {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
        })
    }

    getIssue = async (id: any) => {
        return axios.get(`${process.env.REACT_APP_GET_ISSUE}/${id}`)
    }

    uploadFile = async (data: any) => {
        console.log(data);
        
        return axios.post(`${process.env.REACT_APP_UPLOAD_FILE}`, {
            ...data
        }, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                token: "1",
            }
        })
    }

    getAllCities = async () => {
        return axios.get(`${process.env.REACT_APP_ALL_CITIES}`)
    }

    getAllCategories = async () => {
        return axios.get(`${process.env.REACT_APP_ALL_CATEGORIES}`)
    }

}