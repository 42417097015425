import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function Changepassword({ styles }: any) {

    const [isSubmitting, setisSubmitting] = useState(false);
    const [isDisabled, setisDisabled] = useState(true)

    const validationSchema = Yup.object().shape({
        oldpassword: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 40 characters'),
        newpassword: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 40 characters'),
        confirmpassword: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 40 characters')
    });

    const login = useFormik({
        initialValues: {
            oldpassword: '',
            newpassword: '',
            confirmpassword: ''
        },
        validationSchema,
        onSubmit: (data: any) => {
            setisSubmitting(true)
            setisDisabled(true)
            return new Promise((resolve) => {
                setTimeout(() => {
                    setisSubmitting(false);
                    setisDisabled(false)
                    resolve(undefined);
                    const element: HTMLButtonElement | any = document.getElementById(`trigger_modal_change_password`);
                    element?.click()
                }, 3000)
            })
        },
    });

    useEffect(() => {

        if (!login.errors.oldpassword && !login.errors.newpassword && !login.errors.confirmpassword && login.values.oldpassword !== '' && login.values.newpassword !== '' && login.values.confirmpassword !== '') {
            setisDisabled(false)
        }
        else {
            setisDisabled(true)
        }

    }, [login.values.oldpassword, login.values.newpassword, login.values.confirmpassword, login.errors.oldpassword, login.errors.newpassword, login.errors.confirmpassword])


    return (
        <>
            <div className="modal fade" id="change_password">
                <div className={styles.edit_profile_modal_dialog + " modal-dialog modal-dialog-centered"}>
                    <div className={styles.edit_profile_modal_content + " modal-content"}>
                        <div className={styles.edit_profile_modal_header + " modal-header"}>
                            <div className={styles.edit_profile}>
                                <p className={styles.head_edit_profile}>Change password</p>
                                <p className={styles.head_edit_para}>Please add old and new password</p>
                            </div>
                            <button type="button" className={styles.edit_profile_modal_btn + " btn-close"} data-bs-dismiss="modal">
                                <img src="assets/images/close.png" alt="icon" />
                            </button>
                        </div>
                        <div className={styles.edit_profile_modal_body + " modal-body"}>
                            <form className="chnages" onSubmit={login.handleSubmit}>

                                <div className={styles.input_set_home}>
                                    <label htmlFor="oldpassword" className={styles.input_label + " form-label"}>Old Password</label>
                                    <input type="password" className={styles.input_field + ' form-control' +
                                        (login.errors.oldpassword && login.touched.oldpassword ? ` ${styles.input_field_is_invalid} is-invalid` : '')} id="oldpassword" name="oldpassword" defaultValue={login.values.oldpassword} onChange={login.handleChange} />

                                    <div className={styles.invalid_feedback + " invalid-feedback"} >
                                        {login.errors.oldpassword && login.touched.oldpassword
                                            ? login.errors.oldpassword
                                            : null}
                                    </div>
                                </div>

                                <div className={styles.input_set_home}>
                                    <label htmlFor="newpassword" className={styles.input_label + " form-label"}>New Password</label>
                                    <input type="password" className={styles.input_field + ' form-control' +
                                        (login.errors.newpassword && login.touched.newpassword ? ` ${styles.input_field_is_invalid} is-invalid` : '')} id="newpassword" name="newpassword" defaultValue={login.values.newpassword} onChange={login.handleChange} />

                                    <div className={styles.invalid_feedback + " invalid-feedback"} >
                                        {login.errors.newpassword && login.touched.newpassword
                                            ? login.errors.newpassword
                                            : null}
                                    </div>
                                </div>

                                <div className={styles.input_set_home}>
                                    <label htmlFor="confirmpassword" className={styles.input_label + " form-label"}>Confirm Password</label>
                                    <input type="password" className={styles.input_field + ' form-control' +
                                        (login.errors.confirmpassword && login.touched.confirmpassword ? ` ${styles.input_field_is_invalid} is-invalid` : '')} id="confirmpassword" name="confirmpassword" defaultValue={login.values.confirmpassword} onChange={login.handleChange} />

                                    <div className={styles.invalid_feedback + " invalid-feedback"} >
                                        {login.errors.confirmpassword && login.touched.confirmpassword
                                            ? login.errors.confirmpassword
                                            : null}
                                    </div>
                                </div>

                                <button className={styles.btn_change_password + " btn d-flex justify-content-center align-items-center gap-2"} disabled={isSubmitting}>
                                    {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                                    Change Password
                                </button>

                            </form>
                            <button id="trigger_modal_change_password" type="button" className={styles.btn_change_password + " btn d-none"} data-bs-toggle="modal" data-bs-target="#change_password_success_modal">
                                Change Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}