import React, { useEffect, useState } from 'react';
import "./googleMapWrapper.css"
import GoogleMapReact from 'google-map-react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

export default function GoogleMapComponent({ allIssues, handleselecteditem }: any) {    

    const [map, setMap] = useState<any>(null)
    const [maps, setMaps] = useState<any>(null)
    const [abc, setAbc] = useState<any>(null)
    const [selectedItem, setSelecteditem] = useState<any>()
    const [position, setPosition] = useState<any>({
        top: "10px",
        left: "10px"
    })
    const [defaultProps, setDefaultProps] = useState({
        center: {
            lat: 40.71135768757366,
            lng: -74.01742652688773
        },
        zoom: 13
    });

    useEffect(() => {

        if (allIssues) {
            allIssues.map((issue: any, index: any) => {
                if (index === 0) {
                    setDefaultProps((prev: any) => {
                        return { ...prev, center: { lat: Number(issue.lat), lng: Number(issue.long) } }
                    })
                }
            })
        }
    }, [allIssues])

    const createMarker = (map: any, maps: any, position: any, iconUrl: any, onClick: any, issue: any, isCurrentLocationMarker = false) => {
        const marker = new maps.Marker({
            position, map, icon: {
                url: iconUrl,
                scaledSize: isCurrentLocationMarker ? new maps.Size(30, 30) : new maps.Size(50, 50)
            }
        })

        marker.addListener("mouseover", (e: any) => {
            onClick(marker);
            setPosition({
                top: `${e.domEvent.srcElement.y - 200}px`,
                left: `${e.domEvent.srcElement.x - 145}px`
            })
            setSelecteditem(issue)
        })

        marker.addListener("click", (e: any) => {
            onClick(marker);
            handleselecteditem(issue.id)
        })

        marker.addListener("mouseout", (e: any) => {            
            setSelecteditem(null)
        })

        return marker
    }
    const markerImg = "/assets/images/marker-icon.png"

    const handleApiLoad = async (map: any, maps: any) => {
        setMap(map)
        setMaps(map)
        setAbc({ map, maps })
        let markers: any = []
        if (allIssues) {
            allIssues.map((issue: any) => {
                markers.push(
                    createMarker(map, maps, {
                        lat: Number(issue.lat),
                        lng: Number(issue.long)
                    },
                        markerImg,
                        (marker: any) => {
                            // console.log(marker);
                        },
                        issue
                    )
                )
            })
        }
        const markerCluster = new MarkerClusterer({ map, markers });
    }

    useEffect(() => {
        if (map !== null && maps !== null) {
            if (allIssues) {
                handleApiLoad(abc.map, abc.maps)
            }
        }
    }, [map, allIssues])

    return (

        <>

            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string }}
                    center={defaultProps.center}
                    yesIWantToUseGoogleMapApiInternals={true}
                    zoom={defaultProps.zoom}
                    onGoogleApiLoaded={({ map, maps }) => {
                        handleApiLoad(map, maps)
                    }}
                >
                </GoogleMapReact>
                {selectedItem && <div className="map_data_card_google_map" style={{top: position.top, left: position.left}}>
                    <img src="/assets/images/pipe3154x.png" alt="img" className="pipe_img" />
                    <div className="head_fault_prob d-flex justify-content-between align-items-center">
                        <div className="set_fault_head d-flex justify-content-start align-items-center">
                            <div className="litter_bg">
                                <img src="/assets/images/Water-Leak-bg.png" alt="icon" />
                            </div>
                            <div className="fault_data">
                                <p className="fault_data_head">{selectedItem.remark.replace(/\s+/g, ' ').trim()}</p>
                                <div className="date_para">
                                    <img src="/assets/images/calender-icon.png" alt="icon" className="date_icon_set" />
                                    <span className="fault_date">{selectedItem.created_at}</span>
                                </div>
                            </div>
                        </div>
                        <button className="btn_arrow_forword">
                            <img src="/assets/images/arrow_forward.png" alt="icon" />
                        </button>
                    </div>
                </div>}
            </div>



        </>

    );
};
