import React from "react";
import "./zoomImageModal.css"
import { IssueModel } from "../../../model/issueModel/issueModel";

interface xoomimagemodalprops {
    selectedItem: IssueModel
}

export default function Zoomimagemodal({ selectedItem }: xoomimagemodalprops) {

    const media = selectedItem.media.filter((media: any) => media.type !== "audio")

    return (
        <>
            <div className="modal fade" id="zoom_image_modal">
                <div className="modal-dialog modal-dialog-centered zoom_image_modal_dialog">
                    <div className="modal-content zoom_image_modal_content">
                        <div className="zoom_image_modal_header">
                            <button type="button" className="btn-close close_zoom_image_modal_btn" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body zoom_image_modal_body">
                            <div className="zoom_carousel_open">
                                <div id="pipeline_two" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-indicators round_indicators_set_zoom">
                                        
                                        {media.length !== 0 && <>
                                            {media.map((media: any, index: number) => {
                                                return (
                                                    <button key={index} type="button" data-bs-target="#pipeline_two" data-bs-slide-to={index} className={`round_indicators_zoom ${index === 0 ? "active" : " "}`}></button>
                                                )
                                            })}
                                        </>}

                                        {media.length === 0 && <>
                                            <button type="button" data-bs-target="#pipeline_two" data-bs-slide-to="0" className="round_indicators_zoom active"></button>
                                            <button type="button" data-bs-target="#pipeline_two" data-bs-slide-to="1" className="round_indicators_zoom"></button>
                                            <button type="button" data-bs-target="#pipeline_two" data-bs-slide-to="2" className="round_indicators_zoom"></button>
                                            <button type="button" data-bs-target="#pipeline_two" data-bs-slide-to="3" className="round_indicators_zoom"></button>
                                            <button type="button" data-bs-target="#pipeline_two" data-bs-slide-to="4" className="round_indicators_zoom"></button>
                                        </>}
                                        
                                    </div>
                                    <div className="carousel-inner">
                                        
                                        {media.length !== 0 && <>
                                            {media.map((media: any, index: number) => {
                                                return (
                                                    <div key={index} className={`carousel-item ${index === 0 ? "active" : " "}`}>
                                                        {media.type === "image" && <img src={`https://kats-media.s3.us-west-2.amazonaws.com${media.path}`} alt="img" className="zoom_image_modal_content_image d-block " />}
                                                        {media.type === "video" && <video className="d-block w-100 video_zoom_set" controls>
                                                            <source src={`https://kats-media.s3.us-west-2.amazonaws.com${media.path}`} type="video/mp4" />
                                                        </video>}
                                                    </div>
                                                )
                                            })}
                                        </>}

                                        {media.length === 0 && <>
                                            <div className="carousel-item active">
                                                <img src="/assets/images/pipeline-yellow-1080.png" alt="img" className="d-block w-100" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src="/assets/images/pipeline-yellow-1080.png" alt="img" className="d-block w-100" />
                                            </div>
                                            <div className="carousel-item">
                                                <video className="d-block w-100 video_zoom_set" controls>
                                                    <source src="assets/images/mov_bbb.mp4" type="video/mp4" />
                                                </video>
                                            </div>
                                            <div className="carousel-item">
                                                <video className="d-block w-100 video_zoom_set" controls>
                                                    <source src="assets/images/mov_bbb.mp4" type="video/mp4" />
                                                </video>
                                            </div>
                                            <div className="carousel-item">
                                                <img src="/assets/images/pipeline-yellow-1080.png" alt="img" className="d-block w-100" />
                                            </div>
                                        </>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}