import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface ObjectType {
    SelectedCity: string;
}

const initialState: ObjectType = {
    SelectedCity: "Select city",
}

export const fetchSelectedCity = createAsyncThunk(
    'fetchSelectedCity',
    async (value: any) => {
        return value
    }
)

export const SelectedCityslice = createSlice({
    name: 'SelectCity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSelectedCity.fulfilled, (state, payload) => {
            state.SelectedCity = payload.payload;
        })
    }

});

export const SelectedCityreducer = SelectedCityslice.reducer;