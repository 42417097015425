import { configureStore } from '@reduxjs/toolkit';
import { SelectedCityreducer } from './app-slice';

const Store = configureStore({
    reducer: {
        selectedCity: SelectedCityreducer
    },
});

export type RootState = ReturnType<typeof Store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch

export default Store;