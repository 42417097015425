import React from "react";
import "./reportedItem.css"
import { AllIssueModel } from "../../../model/allIssueModel/allIssueModel";

interface reporteditemprops {
    id: string,
    itemname: string,
    reporteddate: string,
    reporteditemdescription: string,
    reporteditemimage: string,
    reporteditemstatus: string,
    reporteditemtype: string,
}

interface ReportedItemprops {
    reporteditem: reporteditemprops | AllIssueModel,
    handleselecteditem: any,
    handledeletereportitem: any
}


export default function ReportedItem({ category, reporteditem, handledeletereportitem, handleselecteditem }: any) {

    return (
        <>
            {/* <div className="btn_three_dot_dropdown">
                <div className="dropdown">
                    <button type="button" className="btn btn_three_dot_set_report_item dropdown-toggle" data-bs-toggle="dropdown">
                        <img src="/assets/images/three-dot.png" alt="icon" className="three_dot_icon_set_report_item" />
                    </button>
                    <ul className="dropdown-menu btn_three_dot_dropdown_menu">
                        <li>
                            <button className="dropdown-item btn_three_dot_dropdown_menu_item" onClick={() => handledeletereportitem(reporteditem.id)}>
                                <img src="/assets/images/delete-icon.png" alt="icon" />
                                Delete
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="fault_list_nav_link" onClick={() => handleselecteditem(reporteditem.id, reporteditem.reporteditemstatus)}>
                <div className="head_fault d-flex justify-content-between align-items-center">
                    <div className="set_fault_head d-flex justify-content-start align-items-center">
                        <div className="litter_bg">
                            <img src={reporteditem.reporteditemimage} alt="icon" />
                        </div>
                        <div className="fault_data">
                            <p className="fault_data_head">{reporteditem.itemname}</p>
                            <div className="date_para">
                                <img src="/assets/images/calender-icon.png" alt="icon" className="date_icon_set" />
                                <span className="fault_date">21,March
                                    2023</span>
                            </div>
                        </div>
                    </div>

                </div>
                <p className="fault_details">
                    {reporteditem.reporteditemdescription}
                </p>
                <div className="problem_para">
                    {reporteditem.reporteditemtype === "Problem" && <img src="/assets/images/caution-icon.png" alt="icon" className="caution_icon_set" />}
                    {reporteditem.reporteditemtype === "Feedback" && <img src="/assets/images/assistant.png" alt="icon" className="caution_icon_set" />}
                    {reporteditem.reporteditemtype === "Answers" && <img src="/assets/images/quickreply.png" alt="icon" className="caution_icon_set" />}
                    {reporteditem.reporteditemtype}
                </div>
            </div> */}

            <div className="btn_three_dot_dropdown">
                <div className="dropdown">
                    <button type="button" className="btn btn_three_dot_set_report_item dropdown-toggle" data-bs-toggle="dropdown">
                        <img src="/assets/images/three-dot.png" alt="icon" className="three_dot_icon_set_report_item" />
                    </button>
                    <ul className="dropdown-menu btn_three_dot_dropdown_menu">
                        <li>
                            <button className="dropdown-item btn_three_dot_dropdown_menu_item" onClick={() => handledeletereportitem(reporteditem.id)}>
                                <img src="/assets/images/delete-icon.png" alt="icon" />
                                Delete
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="fault_list_nav_link" onClick={() => handleselecteditem(reporteditem.id)}>
                <div className="head_fault d-flex justify-content-between align-items-center">
                    <div className="set_fault_head d-flex justify-content-start align-items-center">
                        <div className="litter_bg">
                            <img src={reporteditem.reporteditemimage} alt="icon" />
                        </div>
                        <div className="fault_data">
                            {reporteditem.remark && <p className="fault_data_head">{reporteditem.remark}</p>}
                            <div className="date_para">
                                <img src="/assets/images/calender-icon.png" alt="icon" className="date_icon_set" />
                                <span className="fault_date">{reporteditem.created_at}</span>
                            </div>
                        </div>
                    </div>

                </div>
                
                {reporteditem.remark && <p className="fault_details">
                    {reporteditem.remark}
                </p>}
                <div className="problem_para">
                    {reporteditem.reporteditemtype === "Problem" && <img src="/assets/images/caution-icon.png" alt="icon" className="caution_icon_set" />}
                    {reporteditem.reporteditemtype === "Feedback" && <img src="/assets/images/assistant.png" alt="icon" className="caution_icon_set" />}
                    {reporteditem.reporteditemtype === "Answers" && <img src="/assets/images/quickreply.png" alt="icon" className="caution_icon_set" />}
                    {reporteditem.reporteditemtype}
                </div>
            </div>
        </>
    )

}